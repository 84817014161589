/**
 * SETTINGS
 * Name: Colors
 ********************************* */


/**
 * ORIGINAL
 *********************** */
$color-shark: #21242c;
$color-red-ribbon: #f00f35;
$color-heather: #b8bfd0;
$color-bright-gray: #373c4a;
$color-trout: #474c5b;
$color-comet: #596176;


/**
 * USABLE & ADDIIONS
 *********************** */
$color-black: #000;
$color-white: #fff;

$color-red: $color-red-ribbon;
$color-dark-red: #ca0224;
$color-blue: $color-shark;
$color-light-blue: $color-heather;
$color-green: #72c472;
$color-orange: #ffb35c;
$color-pink: #ff5c78;

$color-grey: $color-trout;
$color-dark-grey: $color-bright-gray;
$color-light-grey: $color-comet;
$color-pale-white: #cad4d0;

$gradient-blue: linear-gradient(90deg, $color-grey 0%, $color-blue 100%);
$gradient-red: linear-gradient(126deg, #e20020 0%, #9c0016 100%);


/**
 * MAIN
 *********************** */
$color-primary: $color-red;
$color-secondary: $color-light-blue;

$color-foreground: $color-white;
$color-background: $color-blue;

$color-error: $color-pink;
$color-warning: $color-orange;
$color-success: $color-green;


/**
 * UTILS
 *********************** */
$colors: (
  "black": $color-black,
  "white": $color-white
);
