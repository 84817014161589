/**
 * COMPONENT
 * Name: Heading
 ********************************* */

.c-heading {
  position: relative;
  text-align: center;
}


  /**
   * TITLE
   *********************** */
  .c-heading__title {}


  /**
   * DESCRIPTION
   *********************** */
  .c-heading__description {

    @include mq($until: small) {
      @include font-size(14px, 21px);

      &:not(:first-child) {
        @include rem(margin-top, $gutter-size/2);
      }
    }

    @include mq($from: small, $until: large) {

      &:not(:first-child) {
        @include rem(margin-top, $gutter-size);
      }
    }

    @include mq($from: large) {
      @include font-size(18px, $line-height);

      &:not(:first-child) {
        @include rem(margin-top, $gutter-size*1.5);
      }
    }
  }
