/**
 * SCOPE
 * Name: Browser
 ********************************* */

.s-browser {
  position: fixed;
  display: table;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 20px;
  line-height: 26px;
  color: #fff;
  background: rgba(#000, 0.8);
  z-index: 9999999;

  .s-browser__container {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  p {

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  button {
    @include appearance;
    font-family: $font-primary;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
