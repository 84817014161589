/**
 * COMPONENT
 * Name: Newsletter
 ********************************* */

.c-newsletter {
  position: relative;
  @include rem(max-width, 420px);
  margin-right: auto;
  margin-left: auto;

  ::placeholder {
    color: $color-secondary;
  }

  :-ms-input-placeholder {
    color: $color-secondary;
  }

  ::-ms-input-placeholder {
    color: $color-secondary;
  }
}


  /**
   * FIELD
   *********************** */
  .c-newsletter__field {
    position: relative;

    &:not(:first-child) {
      @include rem(margin-top, $gutter-size*0.75);
    }
  }


    /**
     * INPUT
     *********************** */
    .c-newsletter__input {
      @include appearance;
      width: 100%;
      @include rem(height, 50px);
      @include rem(padding, 11px $gutter-size*2.5 11px $gutter-size);
      @include rem(border, 2px solid $color-white);
      @include rem(border-radius, $border-radius);
    }


    /**
     * SUBMIT
     *********************** */
    .c-newsletter__submit {
      @include appearance;
      position: absolute;
      @include rem(top, 2px);
      @include rem(right, 2px);
      @include rem(bottom, 2px);
      @include rem(padding-right, $gutter-size);
      @include rem(padding-left, $gutter-size);
      @include rem(border-left, 2px solid $color-foreground);
      transition: background-color 0.2s ease;
      cursor: pointer;
      z-index: 1;

      &:hover {
        background-color: $color-primary;
      }
    }

      .c-newsletter__icon {
        display: block;
      }


    /**
     * CHOICE
     *********************** */
    .c-newsletter__choice {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;
      cursor: pointer;
    }

      .c-newsletter__checkbox {
        @include appearance;
        position: absolute;
        display: block;
        @include rem(width, 15px);
        @include rem(height, 15px);
        top: 0;
        left: 0;
        @include rem(margin-top, 3px);
        opacity: 0;
        cursor: pointer;
        z-index: 2;

        &:checked + .c-newsletter__fakebox {

          &:before {
            opacity: 1;
          }
        }
      }

      .c-newsletter__fakebox {
        position: relative;
        flex: 0 0 auto;
        display: block;
        @include rem(width, 15px);
        @include rem(height, 15px);
        @include rem(margin-top, 3px);
        @include rem(border, 2px solid $color-white);
        @include rem(border-radius, $border-radius-small);
        z-index: 1;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          @include rem(margin, 2px);
          background-color: $color-primary;
          @include rem(border-radius, 1px);
          opacity: 0;
          transition: opacity 0.2s ease;
        }
      }

      .c-newsletter__label {
        @include font-size(12px, 20px);

        &:not(:first-child) {
          @include rem(padding-left, $gutter-size/2);
        }
      }

        .c-newsletter__link {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
