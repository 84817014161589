/**
 * COMPONENT
 * Name: Presenter
 ********************************* */

.c-presenter {
  position: relative;
}


  /**
   * BACKGROUND
   *********************** */
  .c-presenter__background {}


  /**
   * FOREGROUND
   *********************** */
  .c-presenter__foreground {

    @include mq($from: large) {
      overflow: hidden;
    }
  }

    .c-presenter__container {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: calc(100vw - ((100vw - (#{$container-width} + #{$gutter-size}*2)) / 2));
        top: 0;
        bottom: 0;
        left: 0;
        background-color: $color-background;
        z-index: -1;
      }

      @include mq($until: large) {
        background: none !important;
      }

      @include mq($from: large) {
        @include rem(background-position, $gutter-size*1.75);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

      .c-presenter__inner {
        position: relative;
        background-color: rgba($color-background, 0.85);

        @include mq($until: small) {
          @include rem(padding, 0 0 $gutter-size*1.5 $gutter-size);
        }

        @include mq($from: small, $until: medium) {
          @include rem(padding, 0 $gutter-size*2 $gutter-size*2);
        }

        @include mq($from: medium, $until: large) {
          @include rem(padding, 0 $gutter-size*3 $gutter-size*3);
        }

        @include mq($from: large) {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: flex-start;
          @include rem(padding, $gutter-size*4.5);
        }
      }

        .c-presenter__aside {

          @include mq($until: large) {
            transform: translateY(-$gutter-size*0.75);
          }

          @include mq($from: large) {
            flex: 0 0 auto;
          }
        }

          .c-presenter__thumbnail {
            @include rem(border-radius, 100px);

            @include mq($until: small) {
              @include rem(width, 146px);
              @include rem(height, 146px);
            }
          }

        .c-presenter__main {

          @include mq($until: small) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size/4);
            }
          }

          @include mq($from: small, $until: medium) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size/2);
            }
          }

          @include mq($from: medium, $until: large) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size*0.75);
            }
          }

          @include mq($from: large) {
            flex: 1 1 auto;

            &:not(:first-child) {
              @include rem(margin-left, $gutter-size*4);
            }
          }
        }

          .c-presenter__label {
            font-weight: $font-black;

            @include mq($until: medium) {
              @include font-size(18px, 32px);
            }

            @include mq($from: medium) {
              @include font-size(20px, 32px);
            }
          }

          .c-presenter__title {

            @include mq($until: small) {
              @include font-size(25px, 30px);
            }

            @include mq($from: small, $until: medium) {
              @include font-size(30px, 36px);
            }

            @include mq($from: medium, $until: large) {
              @include font-size(35px, 42px);
            }

            @include mq($from: large) {
              @include font-size(40px, 48px);

              &:not(:first-child) {
                @include rem(margin-top, $gutter-size/2);
              }
            }
          }

          .c-presenter__description {
            @include font-size(14px, 21px);
            color: $color-secondary;

            &:not(:first-child) {

              @include mq($until: large) {
                @include rem(margin-top, $gutter-size*1.5);
              }

              @include mq($from: large) {
                @include rem(margin-top, $gutter-size/2);
              }
            }
          }
