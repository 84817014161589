/**
 * COMPONENT
 * Name: Empty
 ********************************* */

.c-empty {
  position: relative;
  overflow: hidden;

  .v-slider &:not(.c-empty--large) {
    @include rem(box-shadow, 0 2px 6px rgba($color-black, 0.4));
  }
}

.c-empty--large {
  @include rem(max-width, 960px);
  @include rem(margin-top, $gutter-size*3);
  margin-right: auto;
  margin-left: auto;

  &:before {
    content: '';
    position: absolute;
    width: calc(100% + 70px);
    height: 0;
    top: 0;
    left: 50%;
    padding-top: calc(100% + 70px);
    border: 1px dashed $color-light-grey;
    @include rem(border-radius, 515px);
    transform: translate3d(-50%, 0, 0);
  }
}


  /**
   * CONTAINER
   *********************** */
  .c-empty__container {
    position: relative;
    @include rem(max-width, 320px);

    .c-empty--large & {
      @include rem(max-width, 550px);
      @include rem(margin-top, $gutter-size*2.5);
      margin-right: auto;
      margin-left: auto;
    }
  }

    .c-empty__icon {
      height: auto;

      .v-slider .c-empty:not(.c-empty--large) & {
        opacity: 0.4;
      }
    }

    .c-empty__content {
      position: absolute;
      top: calc(50% - #{$gutter-size}*1.25);
      right: 0;
      left: 0;
      @include rem(padding-right, $gutter-size/2);
      @include rem(padding-left, $gutter-size/2);
      text-align: center;
      transform: translateY(-50%);

      .c-empty--large & {
        top: 35%;
        transform: none;
      }
    }

      .c-empty__text {
        @include font-size(14px, 24px);
        font-weight: $font-bold;
        text-transform: uppercase;
        color: $color-secondary;
      }

      .c-empty__more {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size/2);
        }
      }
