/**
 * COMPONENT
 * Name: Button
 ********************************* */

.c-button {
  @include appearance;
  position: relative;
  @include rem(padding, 11px 20px);
  @include font-size(14px, 24px);
  font-weight: $font-bold;
  text-transform: uppercase;
  color: $color-foreground;
  background-color: rgba($color-primary, 0);
  @include rem(border, 2px solid $color-primary);
  @include rem(border-radius, $border-radius);
  transition: background-color 0.2s ease, border-color 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: $color-primary;
  }
}


/**
 * TYPES
 *********************** */
.c-button--filled,
.c-button[data-state="true"] {
  background-color: $color-primary;

  &:hover {
    background-color: $color-dark-red;
    border-color: $color-dark-red;
  }
}

.c-button--small {
  @include rem(padding, 6px $gutter-size);
}

.c-button--animated {

  &:before {
    content: '';
    position: absolute;
    @include rem(top, -$gutter-size);
    @include rem(left, -$gutter-size);
    @include rem(bottom, -$gutter-size);
    @include rem(right, -$gutter-size);
    background: $color-primary;
    @include rem(border-radius, $border-radius);
    z-index: -1;
    opacity: 0.5;
    visibility: hidden;
    transform: scale3d(0.8, 0.5, 1);
  }

  &[data-state="true"] {
    animation: feature-foreground 0.6s ease forwards;

    &:before {
      animation: feature-background 0.3s ease 0.3s forwards;
    }
  }
}


  /**
   * INNER
   *********************** */
  .c-button__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }


    /**
     * ICON
     *********************** */
    .c-button__icon {
      flex: 0 0 auto;

      + .c-button__label {
        @include rem(margin-left, $gutter-size/2);
      }
    }


    /**
     * LABEL
     *********************** */
    .c-button__label {
      flex: 1 1 auto;
    }

    .c-button__label--default {

      .c-button[data-state="true"] & {
        display: none;
      }
    }

    .c-button__label--active {

      .c-button[data-state="false"] &,
      .c-button[data-state="true"]:hover & {
        display: none;
      }
    }

    .c-button__label--hover {
      display: none;

      .c-button[data-state="true"]:hover & {
        display: block;
      }
    }

      .c-button__suffix {

        @include mq($until: small) {
          display: none;
        }
      }
