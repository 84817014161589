/**
 * SETTINGS
 * Name: Fonts
 ********************************* */


/**
 * FOLDER
 *********************** */
$font-folder: "../fonts/";


/**
 * SIZES
 *********************** */
$font-size: 16px;
$line-height: 24px;


/**
 * WEIGHTS
 *********************** */
$font-regular: 400;
$font-semibold: 600;
$font-bold: 700;
$font-black: 900;


/**
 * IMPORTS
 *********************** */
@include font-face("Metropolis", "metropolis-medium", $font-regular, normal);
@include font-face("Metropolis", "metropolis-mediumitalic", $font-regular, italic);
@include font-face("Metropolis", "metropolis-semibold", $font-semibold, normal);
@include font-face("Metropolis", "metropolis-bold", $font-bold, normal);
@include font-face("Metropolis", "metropolis-bolditalic", $font-bold, italic);
@include font-face("Metropolis", "metropolis-black", $font-black, normal);


/**
 * FAMILIES
 *********************** */
$font-primary: "Metropolis", "Arial", sans-serif;
$font-default: $font-primary;
