@mixin font-face($font-family, $file-name, $weight: normal, $style: normal, $display: swap, $id: $font-family, $suffix: "-webfont") {
  @font-face {
    font-display: $display;
    font-family: $font-family;
    font-style: $style;
    font-weight: $weight;
    src:
      url("#{$font-folder}#{$file-name}#{$suffix}.woff2") format("woff2"),
      url("#{$font-folder}#{$file-name}#{$suffix}.woff")  format("woff");
  }
}

@mixin font-size($font-size: $font-size, $line-height: auto) {
  @include rem(font-size, $font-size);

  @if $line-height == auto {
    line-height: ceil($font-size / $line-height) * ($line-height / $font-size);
  } @else if type-of($line-height) == number or $line-height == inherit or $line-height == normal {
    @if type-of($line-height) == number and unit($line-height) == px {
      line-height: $line-height / $font-size;
    } @else {
      line-height: $line-height;
    }
  }
}
