/**
 * VIEWS
 * Name: Footer
 ********************************* */

.v-footer {
  position: relative;
  background-color: $color-grey;
}


  /**
   * CONTAINER
   *********************** */
  .v-footer__container {
    @include rem(padding-bottom, $gutter-size*1.25);

    @include mq($until: small) {
      @include rem(padding-top, $gutter-size*1.25);
    }

    @include mq($from: small, $until: large) {
      @include rem(padding-top, $gutter-size*1.5);
    }

    @include mq($from: large) {
      @include rem(padding-top, $gutter-size*2);
    }
  }


    /**
     * SECTIONS
     *********************** */
    .v-footer__head,
    .v-footer__body,
    .v-footer__foot {

      &:not(:first-child) {
        @include rem(margin-top, $gutter-size*1.25);
        @include rem(padding-top, $gutter-size*1.25);
        border-top: 1px solid $color-light-grey;
      }
    }


      /**
       * INNER
       *********************** */
      .v-footer__inner {

        @include mq($from: large) {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: flex-start;
          justify-content: space-between;
          @include rem(margin-right, -$gutter-size);
          @include rem(margin-left, -$gutter-size);

          .v-footer__body & {
            align-items: center;
          }
        }
      }


        /**
         * AREA
         *********************** */
        .v-footer__area {

          @include mq($until: small) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size*1.5);
            }
          }

          @include mq($from: small, $until: large) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size*2);
            }

            &:not(.v-footer__area--horizontal) {
              @include rem(max-width, 520px);
              margin-right: auto;
              margin-left: auto;
            }
          }

          @include mq($from: large) {
            @include rem(padding-right, $gutter-size);
            @include rem(padding-left, $gutter-size);
          }
        }

        .v-footer__area--horizontal {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          @include rem(margin-right, -$gutter-size/2);
          @include rem(margin-left, -$gutter-size/2);

          @include mq($until: large) {
            align-items: flex-start;
            justify-content: center;
          }

          @include mq($from: large) {
            align-items: center;
          }
        }

        .v-footer__area--wrap {
          flex-wrap: wrap;
        }

          .v-footer__aside {
            flex: 0 0 auto;
            @include rem(padding-right, $gutter-size/2);
            @include rem(padding-left, $gutter-size/2);
          }

          .v-footer__main {
            flex: 0 1 auto;
            @include rem(padding-right, $gutter-size/2);
            @include rem(padding-left, $gutter-size/2);
          }

          .v-footer__cell {
            flex: 0 0 auto;

            @include mq($until: medium) {
              width: 100%;

              &:not(:first-child) {
                @include rem(margin-top, $gutter-size*1.25);
              }
            }

            @include mq($from: medium) {
              @include rem(padding-right, $gutter-size/2);
              @include rem(padding-left, $gutter-size/2);
            }
          }


            /**
             * TITLE
             *********************** */
            .v-footer__title {
              @include font-size(26px, 31px);
              font-weight: $font-black;
            }


            /**
             * CONTENT
             *********************** */
            .v-footer__content {

              &:not(:first-child) {

                @include mq($until: medium) {
                  @include rem(margin-top, $gutter-size/2);
                }

                @include mq($from: medium) {
                  @include rem(margin-top, $gutter-size);

                  .v-footer__area--horizontal & {
                    @include rem(margin-top, $gutter-size/4);
                  }
                }
              }
            }

              .v-footer__text {}

              .v-footer__networks {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;

                @include mq($until: medium) {
                  justify-content: center;
                }

                @include mq($from: medium, $until: large) {
                  @include rem(padding-right, $gutter-size/2);
                }

                @include mq($from: xlarge) {
                  @include rem(padding-right, $gutter-size/2);
                }
              }

                .v-footer__network {
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  align-items: center;
                  justify-content: center;
                  @include rem(width, 40px);
                  @include rem(height, 40px);
                  background-color: $color-red;
                  @include rem(border-radius, 20px);
                  transition: background-color 0.2s ease;

                  &:hover {
                    background-color: $color-dark-red;
                  }

                  &:not(:first-child) {

                    @include mq($until: large) {
                      @include rem(margin-left, $gutter-size*0.75);
                    }

                    @include mq($from: large, $until: xlarge) {
                      @include rem(margin-left, $gutter-size/2);
                    }

                    @include mq($from: xlarge) {
                      @include rem(margin-left, $gutter-size*0.75);
                    }
                  }
                }

              .v-footer__address {

                @include mq($until: medium) {
                  @include font-size(14px, 21px);
                  text-align: center;
                }

                @include mq($from: medium) {
                  text-align: right;
                }

                @include mq($from: medium, $until: large) {
                  @include rem(padding-left, $gutter-size/2);
                  @include font-size(18px, 24px);
                }

                @include mq($from: large, $until: xlarge) {
                  @include font-size(16px, 24px);
                }

                @include mq($from: xlarge) {
                  @include rem(padding-left, $gutter-size/2);
                  @include font-size(18px, 24px);
                }
              }


      /**
       * ITEMS
       *********************** */
      .v-footer__items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }


        /**
         * ITEM
         *********************** */
        .v-footer__item {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          @include font-size(12px, 16px);
          color: $color-light-blue;

          &:not(:first-child) {

            &:before {
              content: '-';
              display: block;
              @include rem(margin-right, 3px);
              @include rem(margin-left, 3px);
            }
          }
        }

          .v-footer__link {

            &:hover {
              text-decoration: underline;
            }
          }

            .v-footer__label {}
