/**
 * COMPONENT
 * Name: Card
 ********************************* */

.c-card {
  position: relative;
}

.c-card--link {}
.c-card--fav {}


  /**
   * WRAPPER
   *********************** */
  .c-card__wrapper {
    display: block;
    @include rem(border-radius, $border-radius);
    @include rem(box-shadow, 0 2px 6px rgba($color-black, 0.4));
    overflow: hidden;

    .c-card--link & {
      transform: translate3d(0, 0, 0);
      transition: transform 0.3s ease;
    }

    .c-card--link:hover & {
      transform: translate3d(0, -$gutter-size/4, 0);
    }
  }


    /**
     * HEAD
     *********************** */
    .c-card__head {
      position: relative;
      overflow: hidden;

      .c-card--fav &:after {
        content: '';
        position: absolute;
        @include rem(width, 180px);
        @include rem(height, 180px);
        top: 0;
        right: 0;
        background: linear-gradient($color-background 40%, rgba($color-background, 0));
        transform: translate3d(90px, -90px, 0) rotate(45deg);
        pointer-events: none;
        z-index: 1;
      }
    }

      .c-card__picture {
        width: 100%;
        height: auto;
      }

      .c-card__progression {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        @include rem(padding, $gutter-size*0.75 $gutter-size);
        background-color: rgba($color-background, 0.9);

        &:before {
          content: '';
          position: absolute;
          @include rem(height, 3px);
          top: 0;
          right: 0;
          left: 0;
          @include rem(margin, $gutter-size*0.75 $gutter-size 0);
          background-color: $color-grey;
          @include rem(border-radius, 3px);
        }
      }

        .c-card__line {
          position: relative;
          @include rem(height, 3px);
          background-color: $color-primary;
          @include rem(border-radius, 3px);
          z-index: 1;
        }


    /**
     * BODY
     *********************** */
    .c-card__body {
      background-color: $color-background;

      .v-slider--team & {
        text-align: center;
      }

      @include mq($until: medium) {
        @include rem(padding, $gutter-size/2);
      }

      @include mq($from: medium) {
        @include rem(padding, $gutter-size/2 $gutter-size*0.75);
      }
    }

    .c-card__body--expand {
      @include rem(min-height, 60px);

      .v-slider--team & {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        
        @include mq($until: small) {
          @include rem(min-height, 75px);
        }
        
        @include mq($from: small) {
          @include rem(min-height, 95px);
        }
      }
    }

      .c-card__heading {
        @include font-size(16px, 19px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        .v-slider--team & {
          text-overflow: initial;
          white-space: initial;
          overflow: initial;

          @include mq($until: small) {
            @include font-size(14px, 18px);
          }
        }
      }

        .c-card__title {
          font-weight: $font-black;
        }

        .c-card__suffix {}

      .c-card__subtitle {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        @include font-size(14px, 21px);
        font-weight: $font-bold;
        color: $color-light-blue;

        .v-slider--team & {
          justify-content: center;

          &:not(:first-child) {
            @include rem(margin-top, $gutter-size/4);
          }

          @include mq($until: small) {
            @include font-size(11px, 16px);
          }

          @include mq($from: small) {
            @include font-size(12px, 16px);
          }
        }
      }

        .c-card__icon {
          flex: 0 0 auto;
          @include rem(margin-right, 7px);
          @include rem(margin-bottom, 1px);

          .v-slider--team & {
            display: none;
          }
        }

        .c-card__text {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          .v-slider--team & {
            text-overflow: initial;
            white-space: initial;
            overflow: initial;
          }
        }


    /**
     * FOOT
     *********************** */
    .c-card__foot {
      background-color: $color-background;
      border-top: 1px solid $color-grey;
    }

      .c-card__alert {
        @include appearance;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        @include rem(padding, $gutter-size*0.75 $gutter-size*0.75 $gutter-size);
        cursor: pointer;
      }

        .c-card__switcher {
          position: relative;
          flex: 0 0 auto;
          cursor: pointer;
        }

          .c-card__track {
            display: block;
            @include rem(width, 40px);
            @include rem(height, 24px);
            background-color: $color-light-grey;
            @include rem(border-radius, 12px);
            transition: background-color 0.2s ease;

            .c-card__alert[data-state="true"] & {
              background-color: $color-success;
            }
          }

          .c-card__control {
            position: absolute;
            display: block;
            @include rem(width, 20px);
            @include rem(height, 20px);
            top: 0;
            left: 0;
            @include rem(margin, 2px);
            @include rem(border-radius, 10px);
            background-color: $color-foreground;
            transform: translate3d(0, 0, 0);
            transition: transform 0.2s ease;

            .c-card__alert[data-state="true"] & {
              transform: translate3d(16px, 0, 0);
            }
          }

        .c-card__label {
          @include font-size(14px, 18px);
          font-weight: $font-bold;
          text-align: left;
          color: $color-secondary;
          transition: color 0.2s ease;

          &:not(:first-child) {
            @include rem(margin-left, $gutter-size*0.75);
          }

          .c-card__alert:hover &,
          .c-card__alert[data-state="true"] & {
            color: $color-foreground;
          }
        }


  /**
   * ACTION
   *********************** */
  .c-card__action {
    position: absolute;
    @include rem(top, $gutter-size);
    @include rem(right, $gutter-size);
    z-index: 2;

    .c-card--link & {
      transform: translate3d(0, 0, 0);
      transition: transform 0.3s ease;
    }

    .c-card--link:hover & {
      transform: translate3d(0, -$gutter-size/4, 0);
    }
  }
