@import "vendors/reset";

*,
*::before,
*::after {
  box-sizing: border-box;
}

@-ms-viewport {
  width: device-width;
}

html,
body {

}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  @include rem(min-width, 320px);
  font-family: $font-default;
  font-size: em($font-size, $rem-baseline);
  font-weight: $font-regular;
  line-height: $line-height / $font-size;
  text-size-adjust: none;
  text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-foreground;
  background: $gradient-blue;
  overflow-x: hidden;

  &.layout-default:before {
    content: '';
    position: absolute;
    @include rem(height, 400px);
    top: 0;
    right: 0;
    left: 0;
    background: linear-gradient(to top, rgba($color-background, 0), $color-background 80%);
    z-index: -1;
  }

  &.page-account:before {
    @include rem(height, 300px);
  }
}

a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

strong,
b {
  font-weight: $font-bold;
}

em,
i {
  font-style: italic;
}

sub,
sup {
  position: relative;

  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img,
svg {
  display: inline-block;
  max-width: 100%;

  vertical-align: top;
}

input,
select {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

*:focus {
  outline: 3px solid $color-secondary;
  // outline: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

@media print {
  html,
  body {
    height: auto;
  }

  html {
    @include rem-baseline(90%);
  }

  body {
    margin: 1cm 1.5cm;
  }

  *,
  *::before,
  *::after {
    border-color: #000 !important;

    background: transparent none !important;

    box-shadow: none !important;

    color: #000 !important;

    filter: none !important;
  }

  a {
    color: #000 !important;
    text-decoration: none;
  }

  p,
  h1,
  h2,
  h3,
  h4 {
    orphans: 3;
    widows: 3;
  }

  h1,
  h2,
  h3,
  h4 {
    page-break-after: avoid;
  }

  img,
  svg {
    filter: grayscale(100%) !important;
  }
}
