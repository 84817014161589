/**
 * VIEWS
 * Name: Section
 ********************************* */

.v-section {
  position: relative;

  &:not(:first-child) {

    @include mq($until: small) {
      @include rem(margin-top, $gutter-size*2);
    }

    @include mq($from: small, $until: large) {
      @include rem(margin-top, $gutter-size*3);
    }

    @include mq($from: large) {
      @include rem(margin-top, $gutter-size*4);
    }
  }
}

.v-section--padded {

  &:not(:first-child) {
    margin-top: 0;

    @include mq($until: small) {
      @include rem(padding-top, $gutter-size*2);
    }

    @include mq($from: small, $until: large) {
      @include rem(padding-top, $gutter-size*3);
    }

    @include mq($from: large) {
      @include rem(padding-top, $gutter-size*3);
    }
  }
}

.v-section--cut {

  &:first-child,
  &:not(:first-child) {
    border-top: 1px solid $color-grey;

    @include mq($until: small) {
      @include rem(margin-top, $gutter-size*2);
      @include rem(padding-top, $gutter-size*2);
    }

    @include mq($from: small, $until: large) {
      @include rem(margin-top, $gutter-size*3);
      @include rem(padding-top, $gutter-size*3);
    }

    @include mq($from: large) {
      @include rem(margin-top, $gutter-size*4);
      @include rem(padding-top, $gutter-size*4);
    }
  }
}

.v-section--pictoral {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include mq($until: medium) {
    @include rem(padding-top, $gutter-size*2);
    @include rem(padding-bottom, $gutter-size*2);
  }

  @include mq($from: medium) {
    @include rem(padding-top, $gutter-size*3);
    @include rem(padding-bottom, $gutter-size*4.5);
  }
}


  /**
   * CONTAINER
   *********************** */
  .v-section__container {
    position: relative;
  }


    /**
     * INNER
     *********************** */
    .v-section__inner {

      @include mq($from: large) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }

      @include mq($from: large, $until: xlarge) {
        @include rem(margin-right, -$gutter-size);
        @include rem(margin-left, -$gutter-size);
      }

      @include mq($from: xlarge) {
        @include rem(margin-right, -$gutter-size*1.75);
        @include rem(margin-left, -$gutter-size*1.75);
      }
    }


      /**
       * MAIN / ASIDE / SIDE
       *********************** */
      .v-section__main,
      .v-section__aside,
      .v-section__side {

        @include mq($from: large, $until: xlarge) {
          @include rem(padding-right, $gutter-size);
          @include rem(padding-left, $gutter-size);
        }

        @include mq($from: xlarge) {
          @include rem(padding-right, $gutter-size*1.75);
          @include rem(padding-left, $gutter-size*1.75);
        }
      }

      .v-section__main {

        @include mq($from: large) {
          flex: 1 1 auto;
        }
      }

      .v-section__aside {

        @include mq($from: large) {
          flex: 0 0 auto;
        }

        @include mq($from: large, $until: xlarge) {
          @include rem(width, 270px);
        }

        @include mq($from: xlarge) {
          @include rem(width, 300px);
        }

        .v-section__inner--flexible & {

          @include mq($from: large) {
            transition: margin-right 0.4s ease, opacity 0.4s ease;
          }
        }

        .v-section__inner--flexible.is-active & {

          @include mq($from: large) {
            opacity: 0;
            pointer-events: none;
          }

          @include mq($from: large, $until: xlarge) {
            @include rem(margin-right, -270px);
          }

          @include mq($from: xlarge) {
            @include rem(margin-right, -300px);
          }
        }
      }

      .v-section__side {

        @include mq($until: large) {
          @include rem(max-width, 550px);
          margin-right: auto;
          margin-left: auto;
          
          &:not(:first-child) {
            @include rem(margin-top, $gutter-size*3);
          }
        }

        @include mq($from: large) {
          width: 50%;
        }
      }


        /**
         * HEAD
         *********************** */
        .v-section__head {

          .v-section__aside & {
            @include rem(margin-top, $gutter-size);
          }
        }

          .v-section__heading {
            position: relative;

            @include mq($until: medium) {

              > * {
                
                &:not(:first-child) {
                  @include rem(margin-top, $gutter-size*0.75);
                }
              }
            }

            @include mq($from: medium) {
              flex: 1 1 auto;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              @include rem(margin-right, -$gutter-size*0.75);
              @include rem(margin-left, -$gutter-size*0.75);

              > * {
                @include rem(padding-right, $gutter-size*0.75);
                @include rem(padding-left, $gutter-size*0.75);
              }
            }
          }

          .v-section__heading--spaced {

            @include mq($from: medium) {
              justify-content: space-between;
            }
          }

          .v-section__title {}

          .v-section__subtitle {
            @include font-size(20px, 30px);
            font-weight: $font-black;
          }

          .v-section__headline {
            @include font-size(16px, 19px);
            font-weight: $font-black;
            text-transform: uppercase;
            @include rem(letter-spacing, 1.5px);
          }


        /**
         * BODY
         *********************** */
        .v-section__body {
          position: relative;

          &:not(:first-child) {
            @include rem(margin-top, $gutter-size);
          }

          .v-section__aside & {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size/2);
            }

            @include mq($until: medium) {
              @include rem(margin-right, -$gutter-size/4);
              @include rem(margin-left, -$gutter-size/4);
            }

            @include mq($from: medium, $until: large) {
              @include rem(margin-right, -7px);
              @include rem(margin-left, -7px);
            }

            @include mq($until: large) {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
            }
          }
        }

          .v-section__item {

            @include mq($until: small) {
              width: 100%;
            }

            @include mq($from: small, $until: medium) {
              width: 50%;
            }

            @include mq($until: medium) {
              @include rem(padding, $gutter-size/4);
            }

            @include mq($from: medium, $until: large) {
              width: 33.333%;
              @include rem(padding, 7px);
            }

            @include mq($from: large) {

              &:not(:first-child) {
                @include rem(margin-top, $gutter-size);
              }
            }
          }


        /**
         * FOOT
         *********************** */
        .v-section__foot {
          position: relative;
          @include rem(margin-top, $gutter-size*1.5);

          .v-section__aside & {
            @include rem(margin-left, -12px);
            text-align: right;
          }
        }

          .v-section__label {

            @include mq($until: medium) {
              @include rem(margin-bottom, $gutter-size/2);
            }

            @include mq($from: medium) {
              @include rem(margin-bottom, $gutter-size);
            }
          }


        /**
         * MORE
         *********************** */
        .v-section__more {

          @include mq($until: large) {
            text-align: center;

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size);
            }
          }

          @include mq($from: large) {
            position: absolute;
            top: 0;
            right: 0;
            @include rem(margin-top, $gutter-size/2);
            @include rem(padding-right, $gutter-size*0.75);
            z-index: 2;
          }
        }

          .v-section__link {
            position: relative;
            display: inline-block;
            white-space: nowrap;

            &:after {
              content: '';
              position: absolute;
              height: 1px;
              right: 0;
              bottom: 0;
              left: 0;
              background-color: $color-light-grey;
              transform: scaleX(0);
              transform-origin: right;
              transition: transform 0.3s ease;
            }

            &:hover {

              &:after {
                transform: scaleX(1);
                transform-origin: left;
              }
            }

            @include mq($until: small) {
              @include font-size(14px, 21px);
            }
          }
