@mixin appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  display: inline-block;
  width: auto;
  margin: 0;
  padding: 0;
  font-family: $font-default;
  font-size: $font-size;
  line-height: $line-height;
  color: inherit;
  background: none;
  border: 0 none;
  border-radius: 0;
}
