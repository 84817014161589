/**
 * COMPONENT
 * Name: Mynav
 ********************************* */

.c-mynav {
  position: relative;
  background-color: $color-background;
}


  /**
   * ITEMS
   *********************** */
  .c-mynav__items {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    @include rem(height, 110px);

    @include mq($until: small) {
      @include rem(margin-right, -$gutter-size);
      @include rem(margin-left, -$gutter-size);
    }

    @include mq($from: small, $until: medium) {
      @include rem(margin-right, -$gutter-size*0.75);
      @include rem(margin-left, -$gutter-size*0.75);
    }

    @include mq($from: medium, $until: large) {
      @include rem(margin-right, -$gutter-size);
      @include rem(margin-left, -$gutter-size);
    }

    @include mq($from: large) {
      @include rem(margin-right, -$gutter-size*1.25);
      @include rem(margin-left, -$gutter-size*1.25);
    }
  }

    .c-mynav__item {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;

      &:nth-last-child(2) {
        flex: 1 1 auto;
      }

      @include mq($until: small) {
        @include rem(padding-right, $gutter-size/2);
        @include rem(padding-left, $gutter-size/2);
        @include font-size(14px, 18px);
      }

      @include mq($from: small, $until: medium) {
        @include rem(padding-right, $gutter-size*0.75);
        @include rem(padding-left, $gutter-size*0.75);
      }

      @include mq($from: medium, $until: large) {
        @include rem(padding-right, $gutter-size);
        @include rem(padding-left, $gutter-size);
      }

      @include mq($from: large) {
        @include rem(padding-right, $gutter-size*1.25);
        @include rem(padding-left, $gutter-size*1.25);
      }
    }

    .c-mynav__item--active {}

      .c-mynav__link {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
      }

        .c-mynav__icon,
        .c-mynav__label {
          transition: color 0.2s ease;
        }

        .c-mynav__icon {
          flex: 0 0 auto;
          color: $color-secondary;

          .c-mynav__link:hover & {
            color: $color-foreground;
          }

          .c-mynav__item--active & {
            color: $color-primary !important;
          }
        }

        .c-mynav__label {
          color: $color-secondary;

          .c-mynav__link:hover & {
            color: $color-foreground;
          }

          .c-mynav__item--active & {
            text-decoration: underline;
            color: $color-foreground;
          }

          @include mq($until: medium) {

            &:not(:first-child) {
              @include rem(padding-left, $gutter-size/4);
            }

            .c-mynav__item--logout & {
              display: none;
            }
          }

          @include mq($from: medium) {

            &:not(:first-child) {
              @include rem(padding-left, $gutter-size/2);
            }
          }
        }
