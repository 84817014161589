/**
 * COMPONENT
 * Name: Tag
 ********************************* */

.c-tag {
  position: relative;
}

.c-tag--dark {}
.c-tag--light {}

  .c-tag__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    font-weight: $font-bold;
    @include rem(border-radius, 20px);

    .c-tag--dark & {
      @include rem(padding, $gutter-size/4 $gutter-size*0.75);
      background-color: $color-background;
    }

    .c-tag--light & {
      @include rem(border, 2px solid $color-foreground);

      @include mq($until: small) {
        @include rem(padding, $gutter-size/4);
      }

      @include mq($from: small) {
        @include rem(padding, $gutter-size/4 $gutter-size*0.75);
      }
    }

    @include mq($until: small) {
      @include font-size(14px, 25px);
    }
  }

    .c-tag__icon {
      flex: 0 0 auto;
    }

    .c-tag__label {
      flex: 0 0 auto;

      &:not(:first-child) {
        @include rem(margin-left, $gutter-size/2);

        .c-tag--light & {

          @include mq($until: small) {
            @include rem(margin-left, $gutter-size/4);
          }
        }
      }
    }

      .c-tag__prefix {

        @include mq($until: small) {
          display: none;
        }
      }
