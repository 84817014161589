/**
 * LAYOUT
 * Name: Grid
 ********************************* */

.l-nojs {
  display: none;
  pointer-events: none;
  z-index: -1;

  html.no-js & {
    position: fixed;
    display: table;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include rem(padding-right, $gutter-size);
    @include rem(padding-left, $gutter-size);
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    background: $color-background;
    z-index: 99999999;
  }
}


  /**
   * CONTAINER
   *********************** */
  .l-nojs__container {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }


    /**
     * SECTION
     *********************** */
    .l-nojs__section {

      &:not(:first-child) {
        @include rem(margin-top, $gutter-size);
      }
    }

    .l-nojs__section--head {
      display: inline-block;
    }
