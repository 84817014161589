/**
 * COMPONENT
 * Name: Message
 ********************************* */

.c-message {
  position: relative;

  &:not(:first-child) {
    @include rem(margin-top, $gutter-size/2);
  }
}


/**
 * TYPES
 *********************** */
.c-message--error {}
.c-message--warning {}
.c-message--success {}

.c-message--large {}


  /**
   * CONTAINER
   *********************** */
  .c-message__container {}


    /**
     * CONTENT
     *********************** */
    .c-message__content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      .c-message--error & {
        color: $color-error;
      }

      .c-message--warning & {
        color: $color-warning;
      }

      .c-message--success & {
        color: $color-success;
      }
    }


      /**
       * ICON
       *********************** */
      .c-message__icon {
        flex: 0 0 auto;
        color: $color-foreground;

        .c-message--error & {
          fill: $color-error;
        }

        .c-message--warning & {
          fill: $color-warning;
        }

        .c-message--success & {
          fill: $color-success;
        }
      }


      /**
       * TEXT
       *********************** */
      .c-message__text {
        @include font-size(12px, 15px);

        &:not(:first-child) {
          @include rem(padding-left, $gutter-size/2);
        }

        .c-message--large & {
          @include font-size(14px, 18px);
          font-weight: $font-bold;
        }
      }
