/**
 * COMPONENT
 * Name: Pagination
 ********************************* */

.c-pagination {
  position: relative;
}


  /**
   * CONTAINER
   *********************** */
  .c-pagination__container {}

    .c-pagination__items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

      .c-pagination__item {
        position: relative;
        @include font-size(16px, 30px);
        font-weight: $font-bold;

        &:not(:first-child) {
          @include rem(margin-left, $gutter-size*0.75);
        }
      }

      .c-pagination__item--prev {}
      .c-pagination__item--next {}

      .c-pagination__item--current {}

        .c-pagination__link {
          display: block;
          @include rem(width, 30px);
          @include rem(height, 30px);
          text-align: center;
          color: $color-secondary;
          transition: color 0.2s ease;

          &:hover {
            color: $color-primary;
          }

          .c-pagination__item--current & {
            color: $color-foreground;

            &:before {
              content: '';
              position: absolute;
              @include rem(top, -$gutter-size/2);
              @include rem(right, -$gutter-size/2);
              @include rem(bottom, -$gutter-size/2);
              @include rem(left, -$gutter-size/2);
              background-color: $color-primary;
              @include rem(border-radius, 25px);
              z-index: -1;
            }
          }
        }
