/**
 * LAYOUT
 * Name: Grid
 ********************************* */

.l-grid {
  position: relative;
}

.l-grid--thirds {}


  /**
   * ITEMS
   *********************** */
  .l-grid__items {
    counter-reset: grid;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include mq($until: small) {
      @include rem(margin-top, -8px);
      @include rem(margin-right, -$gutter-size/4);
      @include rem(margin-left, -$gutter-size/4);
    }

    @include mq($from: small) {
      @include rem(margin-top, -$gutter-size/2);
      @include rem(margin-right, -8px);
      @include rem(margin-left, -8px);
    }

    .l-grid--thirds & {
      justify-content: center;

      @include mq($until: small) {
        @include rem(margin-right, -$gutter-size/3);
        @include rem(margin-left, -$gutter-size/3);
      }

      @include mq($from: small, $until: large) {
        @include rem(margin-right, -$gutter-size);
        @include rem(margin-left, -$gutter-size);
      }

      @include mq($from: large) {
        @include rem(margin-right, -$gutter-size*1.25);
        @include rem(margin-left, -$gutter-size*1.25);
      }
    }
  }


    /**
     * ITEM
     *********************** */
    .l-grid__item {
      counter-increment: grid;

      @include mq($until: small) {
        @include rem(padding, 8px $gutter-size/4);
      }

      @include mq($from: small) {
        @include rem(padding, $gutter-size/2 8px);
      }

      @include mq($until: medium) {
        width: 50%;
      }

      @include mq($from: medium, $until: large) {
        width: 33.333%;
      }

      @include mq($from: large) {
        width: 25%;
      }

      .l-grid--thirds & {

        @include mq($until: small) {
          @include rem(padding, $gutter-size $gutter-size/3);
        }

        @include mq($from: small, $until: large) {
          @include rem(padding, $gutter-size*1.25 $gutter-size);
        }

        @include mq($until: large) {
          width: 50%;
        }

        @include mq($from: large) {
          width: 33.333%;
          @include rem(padding, $gutter-size*1.5 $gutter-size*1.25);
        }
      }
    }
