/**
 * LAYOUT
 * Name: Recent
 ********************************* */

.l-recent {
  // position: relative;
}


  /**
   * CONTAINER
   *********************** */
  .l-recent__container {

    @include mq($from: medium) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      @include rem(margin-right, -7px);
      @include rem(margin-left, -7px);
    }
  }


    /**
     * ASIDE / MAIN
     *********************** */
    .l-recent__aside,
    .l-recent__main {

      @include mq($until: medium) {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size*1.5);
        }
      }

      @include mq($from: medium) {
        @include rem(padding-right, 7px);
        @include rem(padding-left, 7px);
      }
    }

    .l-recent__aside {
      z-index: 2;

      @include mq($from: medium, $until: large) {
        flex: 0 0 auto;
        width: 43%;
      }

      @include mq($from: large) {
        flex: 0 0 auto;
        width: 28.684%;
      }
    }

    .l-recent__main {
      z-index: 1;

      @include mq($from: medium, $until: large) {
        flex: 0 0 auto;
        width: 43%;
      }

      @include mq($from: large) {
        flex: 0 0 auto;
        width: 71.316%;
      }
    }


      /**
       * ITEMS
       *********************** */
      .l-recent__items {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        @include mq($until: medium) {
          @include rem(margin-right, -$gutter-size/4);
          @include rem(margin-left, -$gutter-size/4);
        }

        @include mq($from: medium) {
          @include rem(margin-right, -7px);
          @include rem(margin-left, -7px);
        }
      }


        /**
         * ITEM
         *********************** */
        .l-recent__item {

          .l-recent__aside & {
            width: 100%;
          }

          .l-recent__main & {
            width: 50%;
          }

          @include mq($until: medium) {
            @include rem(padding-right, $gutter-size/4);
            @include rem(padding-left, $gutter-size/4);
          }

          @include mq($from: medium) {
            @include rem(padding-right, 7px);
            @include rem(padding-left, 7px);
          }
        }
