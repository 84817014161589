/**
 * LAYOUT
 * Name: Header
 ********************************* */

.l-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
}


  /**
   * CONTAINER
   *********************** */
  .l-header__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    @include mq($until: small) {
      @include rem(padding-right, $gutter-size/2);
      @include rem(padding-left, $gutter-size/2);
    }

    @include mq($until: medium) {
      @include rem(margin-right, -$gutter-size/2);
      @include rem(margin-left, -$gutter-size/2);
    }

    @include mq($from: small, $until: large) {
      @include rem(padding-right, $gutter-size);
      @include rem(padding-left, $gutter-size);
    }

    @include mq($until: medium) {
      @include rem(padding-top, $gutter-size/2);
      @include rem(padding-bottom, $gutter-size/2);
    }

    @include mq($from: medium) {
      @include rem(padding-top, $gutter-size);
      @include rem(padding-bottom, $gutter-size);
    }

    @include mq($from: medium, $until: xlarge) {
      @include rem(margin-right, -$gutter-size);
      @include rem(margin-left, -$gutter-size);
    }

    @include mq($from: large) {
      @include rem(padding-right, $gutter-size*2);
      @include rem(padding-left, $gutter-size*2);
    }

    @include mq($from: xlarge) {
      @include rem(margin-right, -$gutter-size*2.5);
      @include rem(margin-left, -$gutter-size*2.5);
    }
  }

    .l-header__area {
      flex: 0 0 auto;

      @include mq($until: medium) {
        @include rem(padding-right, $gutter-size/2);
        @include rem(padding-left, $gutter-size/2);
      }

      @include mq($from: medium, $until: xlarge) {
        @include rem(padding-right, $gutter-size);
        @include rem(padding-left, $gutter-size);
      }

      @include mq($from: xlarge) {
        @include rem(padding-right, $gutter-size*2.5);
        @include rem(padding-left, $gutter-size*2.5);
      }
    }

    .l-header__area--flex {
      flex: 1 1 auto;
    }
