/**
 * COMPONENT
 * Name: Featured
 ********************************* */

.c-featured {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include rem(border-radius, $border-radius);
  @include rem(box-shadow, 0 2px 6px rgba(#000, 0.4));
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease;

  &:hover {
    transform: translate3d(0, -$gutter-size/4, 0);
  }
}


  /**
   * WRAPPER
   *********************** */
  .c-featured__wrapper {
    position: relative;
    display: block;
    transition: background-color 0.3s ease;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to right, rgba($color-background, 0.8), rgba($color-background, 0) 115%);
      z-index: -1;
    }

    &:hover {
      background-color: rgba($color-background, 0.5);
    }
  }


    /**
     * CONTAINER
     *********************** */
    .c-featured__container {

      @include mq($until: small) {
        @include rem(padding-top, $gutter-size*7);
        @include rem(padding-bottom, $gutter-size);
      }

      @include mq($from: small, $until: medium) {
        @include rem(padding-top, $gutter-size*8);
        @include rem(padding-bottom, $gutter-size*1.5);
      }

      @include mq($from: medium, $until: large) {
        @include rem(padding-top, $gutter-size*9);
        @include rem(padding-bottom, $gutter-size*2.5);
      }

      @include mq($from: large) {
        @include rem(padding-top, $gutter-size*4);
        @include rem(padding-bottom, $gutter-size*3);
      }

      @include mq($from: large, $until: xlarge) {
        @include rem(padding-left, $gutter-size*1.5);
      }

      @include mq($from: xlarge) {
        @include rem(padding-right, $gutter-size*3);
        @include rem(padding-left, $gutter-size*3);
      }

      .v-slider & {

        @include mq($until: medium) {
          @include rem(height, 380px);
        }

        @include mq($from: medium, $until: large) {
          @include rem(height, 450px);
        }

        @include mq($until: large) {
          @include rem(padding-right, $gutter-size);
          @include rem(padding-left, $gutter-size);
        }

        @include mq($from: large, $until: xlarge) {
          @include rem(padding-right, $gutter-size*1.5);
        }
      }
    }

      .c-featured__inner {
        position: relative;

        @include mq($until: large) {
          height: 100%;
        }

        @include mq($from: large) {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          @include rem(height, 380px);
        }
      }


        /**
         * MAIN
         *********************** */
        .c-featured__main {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: flex-end;

          @include mq($until: large) {
            height: 100%;
          }

          @include mq($from: large) {
            flex: 0 0 auto;
            @include rem(width, 640px);
          }
        }

          .c-featured__label {
            font-weight: $font-black;
            text-transform: uppercase;

            @include mq($until: small) {
              @include font-size(12px, 14px);
            }

            @include mq($from: small, $until: medium) {
              @include font-size(14px, 21px);
            }

            @include mq($until: medium) {
              @include rem(letter-spacing, 1px);
            }

            @include mq($from: medium) {
              @include rem(letter-spacing, 1.5px);
            }
          }

          .c-featured__title {
            font-weight: $font-black;
            @include rem(text-shadow, 0 2px 6px rgba(#000, 0.5));

            @include mq($until: small) {
              @include font-size(38px, 40px);
            }

            @include mq($from: small, $until: medium) {
              @include font-size(47px, 50px);
            }

            @include mq($until: medium) {

              &:not(:first-child) {
                @include rem(margin-top, $gutter-size/2);
              }
            }

            @include mq($from: medium) {

              &:not(:first-child) {
                @include rem(margin-top, $gutter-size*0.75);
              }
            }

            @include mq($from: medium, $until: large) {
              @include font-size(56px, 60px);
            }

            @include mq($from: large) {
              @include font-size(65px, 70px);
            }

            .v-slider & {

              @include mq($until: small) {
                flex: 0 0 auto;
                @include rem(max-height, 120px);
                overflow: hidden;
              }
            }
          }

          .c-featured__description {

            @include mq($until: small) {
              @include font-size(14px, 19px);
            }

            @include mq($until: medium) {

              &:not(:first-child) {
                @include rem(margin-top, $gutter-size*0.75);
              }
            }

            @include mq($from: medium) {

              &:not(:first-child) {
                @include rem(margin-top, $gutter-size);
              }
            }

            @include mq($from: large) {
              flex: 1 0 auto;
              @include font-size(18px, 24px);
            }

            .v-slider & {

              @include mq($until: small) {
                @include rem(max-height, 90px);
              }

              @include mq($from: small, $until: medium) {
                @include rem(max-height, 120px);
              }

              @include mq($until: medium) {
                flex: 0 0 auto;
                overflow: hidden;
              }
            }
          }

          .c-featured__tags {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            @include rem(margin-top, -7px);
            @include rem(margin-right, -5px);
            @include rem(margin-left, -5px);

            &:not(:first-child) {

              @include mq($until: medium) {
                @include rem(margin-top, $gutter-size/2);
              }

              @include mq($from: medium) {
                @include rem(margin-top, $gutter-size);
              }
            }
          }

            .c-featured__tag {
              flex: 0 0 auto;
              @include rem(padding, 7px 5px);
            }

            .c-featured__tag--until {

              @include mq($until: large) {
                display: none;
              }
            }


        /**
         * ASIDE
         *********************** */
        .c-featured__aside {
          position: relative;
          flex: 1 1 auto;
        }

          .c-featured__play {

            @include mq($until: large) {
              display: none;
            }

            @include mq($from: large) {
              position: absolute;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: center;
              @include rem(width, 60px);
              @include rem(height, 60px);
              top: 50%;
              left: 50%;
              @include rem(border-radius, 30px);
              background-color: $color-primary;
              transform: translate3d(-50%, -50%, 0);
            }
          }

          .c-featured__action {
            position: absolute;
            right: 0;
            bottom: 0;
            @include rem(margin-bottom, 12px);
          }
