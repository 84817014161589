/**
 * COMPONENT
 * Name: Contact
 ********************************* */

.c-contact {
  position: relative;
  @include rem(max-width, 250px);
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}


  /**
   * HEAD
   *********************** */
  .c-contact__head {}

    .c-contact__icon {
      color: $color-dark-grey;
      @include rem(border-radius, 85px);
      filter: drop-shadow(0 0 6px rgba($color-black, 0.15));
    }


  /**
   * BODY
   *********************** */
  .c-contact__body {
    position: relative;
    z-index: 1;

    &:not(:first-child) {
      @include rem(margin-top, -$gutter-size*4);
    }
  }

    .c-contact__title {
      @include font-size(26px, 32px);
      font-weight: $font-black;
      color: $color-primary;
    }

    .c-contact__description {

      &:not(:first-child) {
        @include rem(margin-top, $gutter-size*1.5);
      }

      @include mq($until: small) {
        @include font-size(14px, 21px);
      }

      @include mq($from: small) {
        @include font-size(18px, 24px);
      }
    }

    .c-contact__more {

      &:not(:first-child) {
        @include rem(margin-top, $gutter-size*1.5);
      }
    }

      .c-contact__button {}


  /**
   * FOOT
   *********************** */
  .c-contact__foot {

    &:not(:first-child) {
      @include rem(margin-top, $gutter-size*1.5);
    }
  }

    .c-contact__text {
      @include font-size(14px, 21px);
      color: $color-secondary;
    }
