/**
 * COMPONENT
 * Name: Banner
 ********************************* */

.c-banner {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    @include rem(height, 300px);
    top: 0;
    right: 0;
    left: 0;
    background: linear-gradient(to top, rgba($color-background, 0), $color-background 85%);
    pointer-events: none;
    z-index: 2;
  }

  @include mq($until: small) {
    @include rem(margin-top, -$gutter-size*3.5);
  }

  @include mq($from: small, $until: medium) {
    @include rem(margin-top, -$gutter-size*4.5);
  }

  @include mq($from: medium, $until: large) {
    @include rem(margin-top, -$gutter-size*5.5);
  }

  @include mq($from: large) {
    @include rem(margin-top, -$gutter-size*6.5);
  }
}


  /**
   * BACKGROUND
   *********************** */
  .c-banner__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-background;
    overflow: hidden;
    z-index: 1;
  }

    .c-banner__picture {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translate3d(0, 0, 0);
    }


  /**
   * FOREGROUND
   *********************** */
  .c-banner__foreground {
    position: relative;
    background-color: rgba($color-background, 0.2);
    z-index: 3;

    body.page-player &,
    body.page-thema & {
      background-color: rgba($color-background, 0.6);
    }
  }


    /**
     * CONTAINER
     *********************** */
    .c-banner__container {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      z-index: 1;

      @include mq($until: small) {
        @include rem(padding-top, $gutter-size*5.5);
        @include rem(padding-bottom, $gutter-size*10);
      }

      @include mq($from: small, $until: medium) {
        @include rem(padding-top, $gutter-size*6.5);
        @include rem(padding-bottom, $gutter-size*10);
      }

      @include mq($until: medium) {
        align-items: flex-start;
        @include rem(min-height, 580px);
      }

      @include mq($from: medium) {
        align-items: center;
        @include rem(min-height, 650px);
      }

      @include mq($from: medium, $until: large) {
        @include rem(padding-top, $gutter-size*5.5);
        @include rem(padding-bottom, $gutter-size*8);
      }

      @include mq($from: large) {
        @include rem(padding-top, $gutter-size*6.5);
        @include rem(padding-bottom, $gutter-size*9);
      }
    }


      /**
       * INNER
       *********************** */
      .c-banner__inner {
        @include rem(max-width, 700px);
      }


        /**
         * LABEL
         *********************** */
        .c-banner__label {
          font-weight: $font-black;
          text-transform: uppercase;
          @include rem(letter-spacing, 1.5px);
        }


        /**
         * HEADING
         *********************** */
        .c-banner__heading {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: flex-start;

          &:not(:first-child) {

            @include mq($until: medium) {
              @include rem(margin-top, $gutter-size*0.75);
            }

            @include mq($from: medium) {
              @include rem(margin-top, $gutter-size/2);
            }
          }
        }

          .c-banner__title {}


        /**
         * SUFFIX
         *********************** */
        .c-banner__suffix {
          color: $color-primary;
        }


        /**
         * BUTTON
         *********************** */
        .c-banner__button {}

        .c-banner__button--heading,
        .c-button.c-banner__button--heading {

          @include mq($until: medium) {
            display: none !important;
          }

          @include mq($from: medium) {
            flex: 0 0 auto;
            @include rem(width, 140px);
            @include rem(margin-top, 18px !important);
            white-space: nowrap;

            &:not(:first-child) {
              @include rem(margin-left, $gutter-size*1.5);
            }
          }
        }

        .c-banner__button--flow,
        .c-button.c-banner__button--flow {

          @include mq($until: medium) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size/4);
            }
          }

          @include mq($from: medium) {
            display: none !important;
          }
        }


        /**
         * DESCRIPTION
         *********************** */
        .c-banner__description {

          @include mq($until: small) {
            @include font-size(14px, 21px);
          }

          @include mq($from: small, $until: large) {
            @include font-size(16px, 21px);
          }

          @include mq($until: medium) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size*0.75);
            }
          }

          @include mq($from: medium) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size);
            }
          }

          @include mq($from: large) {
            @include font-size(18px, 24px);
          }
        }
