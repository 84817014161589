/**
 * COMPONENT
 * Name: Figure
 ********************************* */

.c-figure {
  position: relative;
}


  /**
   * CONTENT
   *********************** */
  .c-figure__content {
    text-align: center;
  }


    /**
     * TITLE
     *********************** */
    .c-figure__title {
      font-weight: $font-black;
      color: $color-primary;

      @include mq($until: small) {
        @include font-size(28px, 36px);
      }

      @include mq($from: small, $until: medium) {
        @include font-size(42px, 52px);
      }

      @include mq($from: medium, $until: xlarge) {
        @include font-size(56px, 68px);
      }

      @include mq($from: xlarge) {
        @include font-size(70px, 84px);
      }
    }


    /**
     * DESCRIPTION
     *********************** */
    .c-figure__description {
      @include rem(max-width, 280px);
      margin-right: auto;
      margin-left: auto;

      @include mq($until: small) {
        @include font-size(16px, 20px);
      }

      @include mq($from: small, $until: large) {
        @include font-size(18px, 24px);
      }

      @include mq($from: large) {
        @include font-size(22px, 28px);
      }
    }
