/**
 * COMPONENT
 * Name: Features
 ********************************* */

.c-features {
  position: relative;
}

.c-features--small {}

.c-features--branded {
  @include rem(max-width, 960px);
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    width: calc(100% + 70px);
    height: 0;
    top: 0;
    left: 50%;
    padding-top: calc(100% + 70px);
    border: 1px dashed $color-light-grey;
    @include rem(border-radius, 515px);
    transform: translate3d(-50%, 0, 0);
  }
}


  /**
   * CONTAINER
   *********************** */
  .c-features__container {

    .c-features--small & {

      @include mq($until: medium) {
        @include rem(margin-right, -$gutter-size);
        @include rem(margin-left, -$gutter-size);
      }
    }

    .c-features--branded & {

      @include mq($until: small) {
        @include rem(padding-top, $gutter-size);
      }

      @include mq($from: small, $until: medium) {
        @include rem(padding-top, $gutter-size*2);
      }

      @include mq($from: medium, $until: large) {
        @include rem(padding-top, $gutter-size*4);
      }

      @include mq($from: large) {
        @include rem(padding-top, $gutter-size*7);
      }
    }
  }

    .c-features__title {
      text-align: center;

      &:not(:last-child) {

        @include mq($until: medium) {
          @include rem(margin-bottom, $gutter-size*1.5);
        }

        @include mq($from: medium) {
          @include rem(margin-bottom, $gutter-size*3);
        }
      }

      .c-features--small &:not(:last-child) {

        @include mq($from: medium, $until: large) {
          @include rem(margin-bottom, $gutter-size*1.5);
        }

        @include mq($from: large) {
          @include rem(margin-bottom, $gutter-size*2);
        }
      }
    }

    .c-features__items {

      @include mq($from: small) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        @include rem(margin-top, -$gutter-size);
        @include rem(margin-right, -$gutter-size);
        @include rem(margin-left, -$gutter-size);
      }

      .c-features--small & {

        @include mq($until: small) {
          @include rem(margin-right, -$gutter-size/2);
          @include rem(margin-left, -$gutter-size/2);
        }

        @include mq($from: small, $until: medium) {
          @include rem(margin-right, -$gutter-size);
          @include rem(margin-left, -$gutter-size);
        }

        @include mq($until: medium) {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: flex-start;
          justify-content: flex-start;
          overflow-x: scroll;
        }

        @include mq($from: medium, $until: large) {
          @include rem(margin-right, -$gutter-size/2);
          @include rem(margin-left, -$gutter-size/2);
        }

        @include mq($until: large) {
          @include rem(margin-top, -$gutter-size/2);
        }
      }
    }

      .c-features__item {
        @include rem(padding-top, $gutter-size);
        @include rem(padding-bottom, $gutter-size);
        text-align: center;

        @include mq($from: small) {
          @include rem(padding-right, $gutter-size);
          @include rem(padding-left, $gutter-size);
        }

        @include mq($from: small, $until: medium) {
          width: 50%;
        }

        @include mq($from: medium) {
          width: 33.333%;
        }

        .c-features--small & {
          @include rem(padding-top, $gutter-size/2);
          @include rem(padding-bottom, $gutter-size/2);

          @include mq($until: medium) {
            flex: 0 0 auto;
            @include rem(width, 240px);
            @include rem(padding-right, $gutter-size);
            @include rem(padding-left, $gutter-size);
          }

          @include mq($from: medium) {
            width: 33.333%;
          }

          @include mq($from: medium, $until: large) {
            @include rem(padding-right, $gutter-size/2);
            @include rem(padding-left, $gutter-size/2);
          }
        }
      }

        .c-features__icon {

          .c-features--small & {

            @include mq($until: large) {
              @include rem(width, 100px);
              @include rem(height, 60px);
            }
          }
        }

        .c-features__label {
          @include font-size(13px, 20px);
          text-transform: uppercase;

          &:not(:first-child) {
            @include rem(margin-top, $gutter-size);
          }
        }

    .c-features__more {
      text-align: center;

      &:not(:first-child) {

        @include mq($until: medium) {
          @include rem(margin-top, $gutter-size*0.75);
        }

        @include mq($from: medium) {
          @include rem(margin-top, $gutter-size*1.5);
        }
      }
    }
