/**
 * VIEWS
 * Name: Slider
 ********************************* */

.v-slider {
  position: relative;

  .slick-list {
    overflow: visible !important;
  }

    .slick-track {
      min-width: 100%;
    }
}

.v-slider:not(.v-slider--featured) {

  .slick-slide {
    transition: opacity 0.3s ease;
    opacity: 0.4;

    &.slick-active {
      opacity: 1;
    }

    @include mq($from: wide) {

      &.slick-active + .slick-slide:not(.slick-active) {
        opacity: 1;
      }
    }
  }
}


/**
 * TYPES
 *********************** */
.v-slider--videos {}
.v-slider--highlights {}
.v-slider--days {}
.v-slider--programs {}
.v-slider--team {}

.v-slider--recent {
  position: inherit;

  .slick-slide {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  .slick-active {
    opacity: 1;

    + .slick-slide {
      opacity: 1;
    }
  }
}

.v-slider--flow {
  position: inherit;

  @include mq($from: large) {
    @include rem(padding-right, $gutter-size);
  }
}

.v-slider--pictures,
.v-slider--featured {
  @include rem(padding-bottom, $gutter-size*3);
}

.v-slider--pictures {
  
  @include mq($until: medium) {
    @include rem(max-width, 520px);
  }
}


  /**
   * ARROW
   *********************** */
  .v-slider__arrow {

    @include mq($until: large) {
      display: none !important;
    }

    @include mq($from: large) {
      @include appearance;
      position: absolute;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      @include rem(width, 40px);
      @include rem(height, 40px);
      top: 50%;
      @include rem(border, 2px solid $color-primary);
      @include rem(border-radius, 20px);
      transform: translateY(-50%);
      transition: background-color 0.2s ease, filter 0.2s ease, opacity 0.3s ease;
      cursor: pointer;
      z-index: 5;

      &:hover {
        background-color: $color-primary;
      }

      .v-slider--videos &,
      .v-slider--highlights &,
      .v-slider--days &,
      .v-slider--programs &,
      .v-slider--team &,
      .v-slider--recent &,
      .v-slider--flow & {
        filter: drop-shadow(2px 2px 5px $color-black);

        &:hover {
          filter: drop-shadow(2px 2px 5px rgba($color-black, 0.4));
        }

        &.slick-disabled {
          pointer-events: none;
          opacity: 0;
        }
      }

      .v-slider--recent & {
        top: auto;
        @include rem(bottom, 120px);
        transform: translateY(50%);
      }

      .v-slider--flow & {
        top: auto;
        @include rem(bottom, 120px);
        transform: translateY(50%);
      }

      .v-slider--pictures &,
      .v-slider--featured & {
        top: auto;
        bottom: 0;
        transform: none;

        &.slick-disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }
    }
  }

  .v-slider__arrow--prev {

    .v-slider--pictures &,
    .v-slider--featured & {
      right: calc(50% + 30px);
    }

    .v-slider--videos &,
    .v-slider--highlights &,
    .v-slider--days &,
    .v-slider--programs &,
    .v-slider--team &,
    .v-slider--recent &,
    .v-slider--flow & {

      @include mq($until: medium) {
        @include rem(left, $gutter-size/2);
      }

      @include mq($from: medium) {
        @include rem(left, $gutter-size*1.5);
      }
    }
  }

  .v-slider__arrow--next {

    .v-slider--pictures &,
    .v-slider--featured & {
      left: calc(50% + 30px);
    }

    .v-slider--videos &,
    .v-slider--highlights &,
    .v-slider--days &,
    .v-slider--programs &,
    .v-slider--team &,
    .v-slider--recent &,
    .v-slider--flow & {

      @include mq($until: medium) {
        @include rem(right, $gutter-size/2);
      }

      @include mq($from: medium) {
        @include rem(right, $gutter-size*1.5);
      }
    }
  }


  /**
   * ITEMS
   *********************** */
  .v-slider__items {
    position: inherit;

    .v-slider--pictures & {

      @include mq($until: medium) {
        @include rem(margin-left, -$gutter-size/2);
      }

      @include mq($from: medium) {
        @include rem(margin-right, -$gutter-size*1.75);
        @include rem(margin-left, -$gutter-size*1.75);
      }
    }

    .v-slider--featured & {

      @include mq($until: medium) {
        @include rem(margin-left, -$gutter-size);
      }

      @include mq($from: medium, $until: large) {
        @include rem(margin-left, -$gutter-size);
        @include rem(margin-right, $gutter-size);
      }

      @include mq($from: large) {
        @include rem(margin-right, -$gutter-size/2);
        @include rem(margin-left, -$gutter-size/2);
      }
    }

    .v-slider--videos & {

      @include mq($until: medium) {
        @include rem(margin-right, 3px);
        @include rem(margin-left, -$gutter-size/4);
      }

      @include mq($from: medium) {
        max-width: 87%;
        @include rem(margin-right, -7px);
        @include rem(margin-left, -7px);
      }
    }

    .v-slider--recent & {

      @include mq($until: medium) {
        @include rem(margin-right, 3px);
        @include rem(margin-left, -$gutter-size/4);
      }

      @include mq($from: medium) {
        @include rem(margin-right, -7px);
        @include rem(margin-left, -7px);
      }

      @include mq($from: large) {
        max-width: 81.7%;
      }
    }

    .v-slider--flow & {

      @include mq($until: medium) {
        @include rem(margin-right, 3px);
        @include rem(margin-left, -$gutter-size/4);
      }

      @include mq($from: medium) {
        max-width: 87%;
        @include rem(margin-right, -7px);
        @include rem(margin-left, -7px);
      }
    }

    .v-slider--highlights & {

      @include mq($until: medium) {
        @include rem(max-width, 570px);
        @include rem(margin-right, 3px);
        @include rem(margin-left, -$gutter-size/2);
      }

      @include mq($from: medium) {
        @include rem(margin-right, -$gutter-size);
        @include rem(margin-left, -$gutter-size);
      }

      @include mq($from: medium, $until: large) {
        @include rem(max-width, 580px);
      }

      @include mq($from: large, $until: xlarge) {
        max-width: 87%;
      }
    }

    .v-slider--days &,
    .v-slider--programs & {
      margin-right: auto;
      margin-left: 0;

      @include mq($from: small, $until: medium) {
        @include rem(max-width, 400px);
      }

      @include mq($from: medium, $until: large) {
        @include rem(max-width, 680px);
      }

      @include mq($from: large, $until: xlarge) {
        @include rem(max-width, 920px);
      }
    }

    .v-slider--days & {

      @include mq($until: small) {
        @include rem(max-width, 200px);
      }
    }

    .v-slider--programs & {

      @include mq($until: small) {
        @include rem(max-width, 190px);
      }
    }

    .v-slider--team & {

      @include mq($until: medium) {
        @include rem(margin-right, 3px);
        @include rem(margin-left, -$gutter-size/4);
      }

      @include mq($from: medium) {
        @include rem(margin-right, -7px);
        @include rem(margin-left, -7px);
      }
    }
  }


    /**
     * ITEM
     *********************** */
    .v-slider__item {

      @include mq($until: medium) {

        .v-slider--pictures & {
          @include rem(padding-right, $gutter-size/2);
          @include rem(padding-left, $gutter-size/2);
        }

        .v-slider--featured & {
          @include rem(padding-right, $gutter-size/4);
          @include rem(padding-left, $gutter-size/4);
        }

        .v-slider--videos & {
          @include rem(padding-right, $gutter-size/4);
          @include rem(padding-left, $gutter-size/4);
        }

        .v-slider--recent & {
          @include rem(padding-right, $gutter-size/4);
          @include rem(padding-left, $gutter-size/4);
        }

        .v-slider--flow & {
          @include rem(padding-right, $gutter-size/4);
          @include rem(padding-left, $gutter-size/4);
        }

        .v-slider--highlights & {
          @include rem(padding-right, $gutter-size/2);
          @include rem(padding-left, $gutter-size/2);
        }

        .v-slider--team & {
          @include rem(padding-right, $gutter-size/4);
          @include rem(padding-left, $gutter-size/4);
        }
      }

      @include mq($from: medium) {

        .v-slider--pictures & {
          @include rem(padding-right, $gutter-size*1.75);
          @include rem(padding-left, $gutter-size*1.75);
        }

        .v-slider--featured & {
          @include rem(padding-right, $gutter-size/2);
          @include rem(padding-left, $gutter-size/2);
        }

        .v-slider--videos & {
          @include rem(padding-right, 7px);
          @include rem(padding-left, 7px);
        }

        .v-slider--recent & {
          @include rem(padding-right, 7px);
          @include rem(padding-left, 7px);
        }

        .v-slider--flow & {
          @include rem(padding-right, 7px);
          @include rem(padding-left, 7px);
        }

        .v-slider--highlights & {
          @include rem(padding-right, $gutter-size);
          @include rem(padding-left, $gutter-size);
        }

        .v-slider--team & {
          @include rem(padding-right, 7px);
          @include rem(padding-left, 7px);
        }

        html.js body.page-search & {
          transform: translate3d(0, $gutter-size, 0);
          opacity: 0;
        }

        @for $i from 1 through 20 {

          html.js body.page-search .slick-slide:nth-child(#{$i}) & {
            transition: transform 0.5s ease #{$i*0.2}s, opacity 0.5s ease #{$i*0.2}s;
          }
        }

        html.js body.is-ready.page-search & {
          transform: translate3d(0, 0, 0);
          opacity: 1;
        }
      }
    }


      /**
       * INNER
       *********************** */
      .v-slider__inner {

        @include mq($from: large) {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: flex-start;

          .slick-slide:nth-child(odd) & {
            justify-content: flex-start;
          }

          .slick-slide:nth-child(even) & {
            justify-content: flex-end;
          }
        }

        @include mq($from: large, $until: xlarge) {
          @include rem(height, 550px);
        }

        @include mq($from: xlarge) {
          @include rem(height, 610px);
        }
      }


        /**
         * PICTURE
         *********************** */
        .v-slider__picture {
          height: auto;
          @include rem(box-shadow, 0 2px 10px rgba($color-black, 0.4));

          @include mq($from: large) {

            .slick-slide:nth-child(odd) & {
              order: 1;
            }

            .slick-slide:nth-child(even) & {
              order: 2;
            }
          }
        }


        /**
         * CAPTION
         *********************** */
        .v-slider__caption {
          color: $color-secondary;

          @include mq($until: small) {
            @include font-size(14px, 20px);
          }

          @include mq($until: large) {
            @include rem(margin-top, $gutter-size/2);
          }

          @include mq($from: large) {

            .slick-slide:nth-child(odd) & {
              order: 2;
              @include rem(margin-top, $gutter-size/2);
            }

            .slick-slide:nth-child(even) & {
              order: 1;
              @include rem(margin-bottom, $gutter-size/2);
            }
          }
        }
