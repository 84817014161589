/**
 * COMPONENT
 * Name: Navigation
 ********************************* */

.c-navigation {
  position: relative;
}


  /**
   * ITEMS
   *********************** */
  .c-navigation__items {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    @include mq($until: medium) {
      @include rem(margin-right, -$gutter-size/2);
      @include rem(margin-left, -$gutter-size/2);
    }

    @include mq($from: medium, $until: large) {
      @include rem(margin-right, -$gutter-size);
      @include rem(margin-left, -$gutter-size);
    }

    @include mq($from: large) {
      @include rem(margin-right, -$gutter-size*1.25);
      @include rem(margin-left, -$gutter-size*1.25);
    }
  }


    /**
     * ITEM
     *********************** */
    .c-navigation__item {

      @include mq($until: medium) {
        @include rem(padding-right, $gutter-size/2);
        @include rem(padding-left, $gutter-size/2);
      }

      @include mq($from: medium, $until: large) {
        @include rem(padding-right, $gutter-size);
        @include rem(padding-left, $gutter-size);
      }

      @include mq($until: large) {

        &:last-child {
          margin-right: 0;
          margin-left: auto;
        }
      }

      @include mq($from: large) {
        @include rem(padding-right, $gutter-size*1.25);
        @include rem(padding-left, $gutter-size*1.25);
      }
    }


      /**
       * LINK
       *********************** */
      .c-navigation__link {
        font-weight: $font-bold;
        transition: color 0.2s ease;

        &:hover,
        .c-navigation__item--active & {
          color: $color-primary;
        }

        @include mq($until: small) {
          @include font-size(15px, 18px);
        }

        @include mq($from: small, $until: medium) {
          @include font-size(18px, 22px);
        }

        @include mq($from: medium) {
          @include font-size(20px, 24px);
        }
      }


      /**
       * BUTTON
       *********************** */
      .c-navigation__button {
        @include appearance;
        @include font-size(14px, 17px);
        white-space: nowrap;
        cursor: pointer;
      }

        .c-navigation__icon {
          transition: color 0.2s ease;

          .c-navigation__button:hover & {
            color: $color-primary;
          }
        }

        .c-navigation__label {

          @include mq($until: large) {
            display: none;
          }

          @include mq($from: large) {
            position: relative;
            color: rgba($color-foreground, 0.6);
            transition: color 0.2s ease;

            &:before {
              content: "|";
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              font-weight: $font-bold;
              color: $color-foreground;
              visibility: hidden;
            }

            .c-navigation__button:hover & {
              color: rgba($color-secondary, 0.6);

              &:before {
                animation: blink 1s infinite;
                visibility: visible;
              }
            }

            &:not(:first-child) {
              @include rem(margin-left, $gutter-size/2);
            }
          }
        }

          .c-navigation__suffix {

            @include mq($until: xlarge) {
              display: none;
            }
          }
