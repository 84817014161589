/**
 * LAYOUT
 * Name: Container
 ********************************* */

.l-container {
  @include rem(max-width, $container-width + $gutter-size*2);
  margin-right: auto;
  margin-left: auto;
  @include rem(padding-right, $gutter-size);
  @include rem(padding-left, $gutter-size);

  .l-container {
    @include rem(max-width, $container-width);
    padding-right: 0;
    padding-left: 0;
  }

  .l-container--small {
    @include rem(max-width, $container-small);
  }

  .l-container--xsmall {
    @include rem(max-width, $container-xsmall);
  }
}


/**
 * TYPES
 *********************** */
.l-container--small {
  @include rem(max-width, $container-small + $gutter-size*2);
}

.l-container--xsmall {
  @include rem(max-width, $container-xsmall + $gutter-size*2);
}
