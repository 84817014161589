/**
 * VIEWS
 * Name: Guide
 ********************************* */

.v-guide {
  position: relative;
}


  /**
   * SECRION
   *********************** */
  .v-guide__section {}


    /**
     * DAYS
     *********************** */
    .v-guide__days {}

      .v-guide__day {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        text-align: center;
        @include rem(border, 2px solid $color-grey);
        @include rem(border-radius, 85px);
        transition: background-color 0.2s ease, border-color 0.2s ease;

        &:hover {
          border-color: $color-foreground;
        }

        &.is-active {
          border-color: $color-primary;

          &:hover {
            background-color: $color-primary;
          }
        }

        &.is-current {
          background-color: $color-primary;
          border-color: $color-primary;
        }

        &.is-disabled {
          // pointer-events: none;
        }

        @include mq($until: small) {
          @include rem(width, 90px);
          @include rem(height, 90px);
        }

        @include mq($from: small, $until: medium) {
          @include rem(width, 120px);
          @include rem(height, 120px);
        }

        @include mq($from: medium, $until: large) {
          @include rem(width, 150px);
          @include rem(height, 150px);
        }

        @include mq($from: large) {
          @include rem(width, 170px);
          @include rem(height, 170px);
        }
      }

        .v-guide__label {
          font-weight: $font-black;

          .v-guide__day.is-disabled & {
            transition: opacity 0.2s ease;
            opacity: 0.5;
          }

          .v-guide__day.is-disabled:hover &,
          .v-guide__day.is-disabled.is-current & {
            opacity: 1;
          }

          @include mq($until: small) {
            @include font-size(14px, 17px);
          }

          @include mq($from: small, $until: medium) {
            @include font-size(16px, 21px);
          }

          @include mq($from: medium, $until: large) {
            @include font-size(20px, 24px);
          }

          @include mq($from: large) {
            @include font-size(22px, 26px);
          }
        }


    /**
     * HEADING
     *********************** */
    .v-guide__heading {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }

      .v-guide__icon {}

      .v-guide__title {
        font-weight: $font-black;
        text-transform: uppercase;
        @include rem(letter-spacing, 1.5px);

        &:not(:first-child) {
          @include rem(margin-left, $gutter-size*0.75);
        }
      }
