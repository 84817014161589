/**
 * COMPONENT
 * Name: Fav
 ********************************* */

.c-fav {
  @include appearance;
  position: relative;
  display: block;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    @include rem(top, -$gutter-size*0.75);
    @include rem(left, -$gutter-size*0.75);
    @include rem(bottom, -$gutter-size*0.75);
    @include rem(right, -$gutter-size*0.75);
    background: $color-primary;
    @include rem(border-radius, 100px);
    z-index: -1;
    opacity: 0.5;
    visibility: hidden;
    transform: scale3d(0.5, 0.5, 1);
  }

  &[data-state="true"] {
    animation: feature-foreground 0.6s ease forwards;

    &:before {
      animation: feature-background 0.3s ease 0.3s forwards;
    }
  }
}

  .c-fav__icon {
    color: $color-foreground;
    fill: transparent;
    transition: color 0.2s ease, fill 0.2s ease;

    .c-fav[data-state="false"]:hover & {
      color: $color-primary;
    }

    .c-fav[data-state="true"] & {
      color: $color-primary;
      fill: $color-primary;
    }

    .c-fav[data-state="true"]:hover & {
      color: $color-dark-red;
      fill: $color-dark-red;
    }
  }
