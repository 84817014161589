/**
 * COMPONENT
 * Name: Menu
 ********************************* */

.c-menu {
  position: relative;
}


  /**
   * ITEMS
   *********************** */
  .c-menu__items {
    columns: 2;

    @include mq($from: large, $until: xlarge) {
      @include rem(column-gap, $gutter-size*4);
    }

    @include mq($from: xlarge) {
      @include rem(column-gap, $gutter-size*7);
    }
  }

    .c-menu__item {
      white-space: nowrap;

      @include mq($until: small) {
        @include font-size(14px, 28px);
      }
    }

      .c-menu__link {
        transition: color 0.2s ease;

        &:hover {
          color: $color-pale-white;
        }
      }
