/**
 * COMPONENT
 * Name: Logo
 ********************************* */

.c-logo {
  position: relative;
  display: block;
  @include rem(border-radius, 45px);
  @include rem(box-shadow, 0 0 6px rgba($color-black, 0.15));
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    @include rem(top, 2px);
    @include rem(right, 2px);
    @include rem(bottom, 2px);
    @include rem(left, 2px);
    background: $gradient-red;
    @include rem(border-radius, 45px);
    z-index: -1;

    .v-footer & {
      display: none;
    }
  }
}

  .c-logo__icon {
    @include rem(border-radius, 45px);
    filter: drop-shadow(0 0 4px rgba($color-black, 0.15));

    .l-header & {

      @include mq($until: small) {
        @include rem(width, 50px);
        @include rem(height, 50px);
      }

      @include mq($from: small, $until: large) {
        @include rem(width, 70px);
        @include rem(height, 70px);
      }
    }
  }
