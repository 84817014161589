/**
 * VIEWS
 * Name: Flow
 ********************************* */

.v-flow {
  position: relative;
}


  /**
   * WRAPPER
   *********************** */
  .v-flow__wrapper {
    @include rem(max-width, 1600px);
    margin-right: auto;
    margin-left: auto;
  }


    /**
     * ASIDE
     *********************** */
    .v-flow__aside {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @include mq($until: small) {
        @include rem(height, 300px);
      }

      @include mq($from: small, $until: medium) {
        @include rem(height, 360px);
      }

      @include mq($from: medium, $until: large) {
        @include rem(height, 420px);
      }

      @include mq($until: large) {
        position: relative;
        @include rem(margin-right, -$gutter-size);
        @include rem(margin-left, -$gutter-size);
      }

      @include mq($from: large) {
        position: absolute;
        @include rem(height, 590px);

        @include mq($until: 1599px) {
          width: 39%;
          left: 0;
        }

        @include mq($from: 1600px) {
          @include rem(width, 625px);
          @include rem(margin-left, -230px);
        }
      }
    }

      .v-flow__inner {
        display: block;
        height: 100%;
        background: linear-gradient(to bottom, rgba($color-background, 0.7), rgba($color-background, 0) 50%);
      }

        .v-flow__content {
          height: 100%;
          transition: background-color 0.3s ease;

          .v-flow__inner:hover & {
            background-color: rgba($color-background, 0.5);
          }

          @include mq($until: small) {
            @include rem(padding, $gutter-size*1.5 $gutter-size*1.5 $gutter-size*1.5 $gutter-size*3);
          }

          @include mq($from: small, $until: large) {
            @include rem(padding, $gutter-size*2 $gutter-size*2 $gutter-size*2 $gutter-size*3.5);
          }

          @include mq($from: large) {
            @include rem(padding, $gutter-size*2.5 $gutter-size*2.5 $gutter-size*2.5 $gutter-size*4);
          }
        }

          .v-flow__play {
            position: absolute;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            left: 50%;
            background-color: $color-primary;
            z-index: 1;

            @include mq($until: large) {
              @include rem(width, 50px);
              @include rem(height, 50px);
              top: 55%;
              @include rem(border-radius, 25px);
              transform: translate3d(-50%, 0, 0);
            }

            @include mq($from: large) {
              @include rem(width, 60px);
              @include rem(height, 60px);
              top: 50%;
              @include rem(border-radius, 30px);
              transform: translate3d(-50%, -50%, 0);
            }
          }

          .v-flow__title {
            position: relative;
            font-weight: $font-black;
            z-index: 2;

            &:before {
              content: '';
              position: absolute;
              @include rem(width, 14px);
              @include rem(height, 14px);
              top: 55%;
              @include rem(left, -30px);
              @include rem(border-radius, 7px);
              background-color: $color-primary;
              transform: translateY(-50%);
            }

            @include mq($until: small) {
              @include font-size(30px, 36px);
            }

            @include mq($from: small, $until: large) {
              @include font-size(35px, 42px);
            }

            @include mq($from: large) {
              @include font-size(40px, 48px);
            }
          }


    /**
     * MAIN
     *********************** */
    .v-flow__main {

      @include mq($until: large) {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size);
        }
      }

      @include mq($from: large) {
        @include rem(padding-left, $gutter-size*1.5);

        @include mq($until: 1599px) {
          width: 61%;
          margin-right: 0;
          margin-left: auto;
        }

        @include mq($from: 1600px) {
          @include rem(margin-left, 395px);
        }
      }
    }

      .v-flow__container {

        @include mq($from: large) {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          @include rem(min-height, 590px);
          @include rem(padding-top, $gutter-size/2);
        }
      }


        /**
         * SUMMARY
         *********************** */
        .v-flow__summary {
          position: relative;
          z-index: 2;

          @include mq($from: large) {
            flex: 1 0 auto;
            @include rem(max-width, 720px);
            @include rem(padding-bottom, $gutter-size*2);
          }
        }


          /**
           * ACTION
           *********************** */
          .v-flow__action {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;

            @include mq($from: large) {
              @include rem(margin-top, $gutter-size/4);
            }
          }


          /**
           * SUBTITLE
           *********************** */
          .v-flow__subtitle {
            @include rem(padding-right, $gutter-size*3);
          }


          /**
           * LABEL
           *********************** */
          .v-flow__label {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size/4);
            }

            @include mq($until: medium) {
              @include font-size(18px, 22px);
            }

            @include mq($from: medium) {
              @include font-size(22px, 26px);
            }
          }


          /**
           * DESCRIPTION
           *********************** */
          .v-flow__description {
            color: $color-secondary;

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size/2);
            }

            + .v-flow__live:not(:first-child) {
              @include rem(margin-top, $gutter-size*0.75);
            }
          }


          /**
           * LIVE
           *********************** */
          .v-flow__live {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size*1.5);
            }
          }

          .v-flow__progression {
            flex: 0 1 280px;
            position: relative;
            @include rem(height, 3px);
            background-color: $color-grey;
            @include rem(border-radius, 3px);
          }

            .v-flow__line {
              position: relative;
              @include rem(height, 3px);
              background-color: $color-primary;
              @include rem(border-radius, 3px);
              z-index: 1;
            }

          .v-flow__pastil {
            flex: 0 0 auto;
            @include rem(padding-right, $gutter-size/4);
            @include rem(padding-left, $gutter-size/4);
            @include font-size(12px, 21px);
            font-weight: $font-black;
            text-transform: uppercase;
            color: $color-foreground;
            background-color: $color-primary;
            @include rem(border-radius, $border-radius-small);
            
            &:not(:first-child) {
              @include rem(margin-left, $gutter-size/2);
            }
          }


          /**
           * TAGS
           *********************** */
          .v-flow__tags {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            @include rem(margin-top, -7px);
            @include rem(margin-right, -5px);
            @include rem(margin-left, -5px);

            &:not(:first-child) {

              @include mq($until: medium) {
                @include rem(margin-top, $gutter-size/4);
              }

              @include mq($from: medium) {
                @include rem(margin-top, $gutter-size/2);
              }
            }
          }

            .v-flow__tag {
              flex: 0 0 auto;
              @include rem(padding, 7px 5px);
            }


        /**
         * UPCOMING
         *********************** */
        .v-flow__upcoming {

          @include mq($until: large) {
            
            &:not(:first-child) {
              @include rem(margin-top, $gutter-size*2);
            }
          }

          @include mq($from: large) {
            @include rem(margin-bottom, $gutter-size*1.5);
          }
        }


          /**
           * SLIDER
           *********************** */
          .v-flow__slider {

            @include mq($until: large) {

              &:not(:first-child) {
                @include rem(margin-top, $gutter-size);
              }
            }

            @include mq($from: large) {
              @include rem(margin-left, -$gutter-size*4.5);

              &:not(:first-child) {
                @include rem(margin-top, $gutter-size/2);
              }
            }
          }


          /**
           * MORE
           *********************** */
          .v-flow__more {
            @include rem(padding-left, $gutter-size);

            @include mq($until: large) {
              text-align: center;

              &:not(:first-child) {
                @include rem(margin-top, $gutter-size);
              @include rem(padding-right, $gutter-size);
              }
            }

            @include mq($from: large) {
              position: absolute;
              right: 0;
              @include rem(bottom, 230px);
              @include rem(padding-right, $gutter-size*1.75);
              z-index: 2;
            }
          }

            .v-flow__link {
              white-space: nowrap;

              @include mq($until: small) {
                @include font-size(14px, 21px);
              }
            }
