/**
 * COMPONENT
 * Name: Breadcrumb
 ********************************* */

.c-breadcrumb {
  position: relative;
  border-top: 1px solid $color-grey;
}


  /**
   * ITEMS
   *********************** */
  .c-breadcrumb__items {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    @include mq($until: large) {
      @include rem(padding-top, $gutter-size*0.75);
      @include rem(padding-bottom, $gutter-size*0.75);
    }

    @include mq($from: large) {
      @include rem(padding-top, $gutter-size);
      @include rem(padding-bottom, $gutter-size);
    }
  }


    /**
     * ITEM
     *********************** */
    .c-breadcrumb__item {

      @include mq($until: large) {
        @include font-size(12px, $line-height);

        &:not(:nth-last-child(2)) {
          display: none;
        }
      }

      @include mq($from: large) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        @include font-size(14px, $line-height);

        &:not(:first-child) {

          &:before {
            content: '>';
            display: block;
            @include rem(margin-right, 5px);
            @include rem(margin-left, 5px);
            color: $color-light-blue;
          }
        }
      }
    }

      .c-breadcrumb__link {

        &:hover {
          text-decoration: underline;
        }

        @include mq($until: large) {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;

          .c-breadcrumb__item:nth-last-child(2) & {

            &:before {
              content: '<';
              display: block;
              @include rem(margin-right, 5px);
            }
          }
        }
      }

      .c-breadcrumb__label {
        color: $color-primary;
      }
