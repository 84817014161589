/**
 * LAYOUT
 * Name: Overlay
 ********************************* */

.l-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color-background, 0.85);
  transition: opacity 0.3s ease, z-index 0.3s ease;
  pointer-events: none;
  opacity: 0;
  z-index: -1;

  &.is-active {
    pointer-events: all;
    opacity: 1;
    z-index: 9999;
  }
}


  /**
   * CONTAINER
   *********************** */
  .l-overlay__container {
    width: 100%;
    height: 100%;

    @include mq($from: medium) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
    }

    @include mq($from: medium, $until: xlarge) {
      @include rem(padding, $gutter-size);
    }

    @include mq($from: xlarge) {
      @include rem(padding, $gutter-size*2);
    }
  }


    /**
     * CONTENT
     *********************** */
    .l-overlay__content {
      position: relative;
      @include rem(max-width, 900px);
      margin-right: auto;
      margin-left: auto;
      transform: translate3d(0, $gutter-size*2, 0);
      transition: transform 0.5s ease;

      .l-overlay.is-active & {
        transform: translate3d(0, 0, 0);
      }

      @include mq($until: medium) {
        height: 100%;
      }

      @include mq($from: medium, $until: large) {
        @include rem(padding, $gutter-size*1.5 0);
      }

      @include mq($from: large) {
        @include rem(padding, $gutter-size*2.5);
      }
    }

      .l-overlay__close {
        @include appearance;
        position: absolute;
        display: block;
        cursor: pointer;
        z-index: 2;

        @include mq($until: medium) {
          @include rem(top, $gutter-size/2);
          @include rem(right, $gutter-size/2);
        }

        @include mq($from: medium) {
          top: 0;
          right: 0;
        }
      }

        .l-overlay__icon {

          @include mq($until: large) {
            @include rem(width, 22px);
            @include rem(height, 22px);
          }
        }
