/**
 * COMPONENT
 * Name: Jump
 ********************************* */

.c-jump {
  position: relative;
  @include rem(border-radius, $border-radius);
  @include rem(box-shadow, 0 2px 6px rgba($color-black, 0.4));
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease;
  overflow: hidden;

  &:hover {
    transform: translate3d(0, -$gutter-size/4, 0);
  }
}


  /**
   * LINK
   *********************** */
  .c-jump__link {
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

    .c-jump__inner {
      @include rem(padding, $gutter-size $gutter-size*1.5);
      background-color: rgba($color-background, 0.6);

      .c-jump--primary & {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        @include rem(min-height, 120px);
      }

      .c-jump--secondary & {
        @include rem(min-height, 180px);
      }
    }

      .c-jump__label {
        font-weight: $font-black;

        @include mq($until: medium) {
          @include font-size(18px, 22px);
        }

        @include mq($from: medium) {
          @include font-size(20px, 22px);
        }
      }

      .c-jump__title {
        font-weight: $font-black;

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size/2);
        }

        .c-jump--primary & {
          text-align: center;
          text-transform: uppercase;
        }

        @include mq($until: medium) {
          @include font-size(20px, 24px);
        }

        @include mq($from: medium, $until: large) {
          @include font-size(22px, 26px);
        }

        @include mq($from: large) {
          @include font-size(24px, 26px);
        }
      }
