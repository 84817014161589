/**
 * COMPONENT
 * Name: Popin
 ********************************* */

.c-popin {
  position: relative;

  @include mq($until: medium) {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}


  /**
   * CONTAINER
   *********************** */
  .c-popin__container {
    background-color: $color-dark-grey;

    @include mq($until: small) {
      @include rem(padding-right, $gutter-size/2);
      @include rem(padding-left, $gutter-size/2);
    }

    @include mq($from: small, $until: medium) {
      @include rem(padding-right, $gutter-size);
      @include rem(padding-left, $gutter-size);
    }

    @include mq($until: medium) {
      min-height: 100%;
      @include rem(padding-top, $gutter-size*2.5);
      @include rem(padding-bottom, $gutter-size*2);
    }

    @include mq($from: medium) {
      @include rem(border-radius, $border-radius*2);
      @include rem(box-shadow, 0 2px 10px rgba($color-black, 0.3));
    }

    @include mq($from: medium, $until: large) {
      @include rem(padding, $gutter-size*2 $gutter-size*1.5 $gutter-size);
    }

    @include mq($from: large) {
      @include rem(padding, $gutter-size*3);
    }
  }


    /**
     * HEAD
     *********************** */
    .c-popin__head {}


    /**
     * BODY
     *********************** */
    .c-popin__body {
      text-align: center;

      @include mq($until: medium) {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size);
        }
      }

      @include mq($from: medium, $until: large) {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size/2);
        }
      }

      @include mq($from: large) {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size);
        }
      }
    }


    /**
     * FOOT
     *********************** */
    .c-popin__foot {

      &:not(:first-child) {
        border-top: 1px solid $color-grey;
      }

      @include mq($until: medium) {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size*1.5);
          @include rem(padding-top, $gutter-size*1.5);
        }
      }

      @include mq($from: medium) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        @include rem(margin-right, -$gutter-size/2);
        @include rem(margin-left, -$gutter-size/2);
      }

      @include mq($from: medium, $until: large) {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size);
          @include rem(padding-top, $gutter-size);
        }
      }

      @include mq($from: large) {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size*2);
          @include rem(padding-top, $gutter-size*2);
        }
      }
    }

      .c-popin__side {
        text-align: center;

        @include mq($until: medium) {

          &:not(:first-child) {
            @include rem(margin-top, $gutter-size*0.75);
          }
        }

        @include mq($from: medium) {
          flex: 1 1 auto;
          @include rem(padding-right, $gutter-size/2);
          @include rem(padding-left, $gutter-size/2);
        }
      }

        .c-popin__label {

          @include mq($until: large) {
            @include font-size(18px, 22px);
          }

          @include mq($from: large) {
            @include font-size(22px, 26px);
          }
        }

        .c-popin__button {}
