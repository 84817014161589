/**
 * COMPONENT
 * Name: Label
 ********************************* */

.c-label {
  position: relative;

  @include mq($until: small) {
    @include font-size(16px, 24px);
  }

  @include mq($from: small, $until: medium) {
    @include font-size(18px, 24px);
  }

  @include mq($from: medium, $until: large) {
    @include font-size(20px, 26px);
  }

  @include mq($from: large) {
    @include font-size(22px, 26px);
  }
}
