/**
 * COMPONENT
 * Name: Subtitle
 ********************************* */

.c-subtitle {
  position: relative;
  font-weight: $font-black;

  @include mq($until: small) {
    @include font-size(20px, 24px);
  }

  @include mq($from: small, $until: medium) {
    @include font-size(24px, 28px);
  }

  @include mq($from: medium, $until: large) {
    @include font-size(27px, 32px);
  }

  @include mq($from: large) {
    @include font-size(30px, 36px);
  }
}
