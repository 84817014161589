/**
 * SCOPE
 * Name: Content
 ********************************* */

.s-content {

  h2 {
    @extend .c-subtitle;

    &:not(:first-child) {

      @include mq($until: medium) {
        @include rem(margin-top, $gutter-size*2);
      }

      @include mq($from: medium) {
        @include rem(margin-top, $gutter-size*3);
      }
    }
  }

  h3 {
    @extend .c-label;

    @include mq($until: medium) {

      &:not(:first-child) {
        @include rem(margin-top, $gutter-size);
      }
    }

    @include mq($from: medium) {

      &:not(:first-child) {
        @include rem(margin-top, $gutter-size*2);
      }
    }
  }

  p,
  ul,
  ol {
    color: $color-secondary;

    @include mq($until: small) {
      @include font-size(14px, 21px);
    }

    @include mq($until: medium) {

      &:not(:first-child) {
        @include rem(margin-top, $gutter-size*0.5);
      }
    }

    @include mq($from: medium) {

      &:not(:first-child) {
        @include rem(margin-top, $gutter-size);
      }
    }
  }

  p {}

  ul,
  ol {

    li {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
      }

      @include mq($until: small) {
        @include rem(padding-left, $gutter-size*1.5);
      }

      @include mq($from: small) {
        @include rem(padding-left, $gutter-size*2.5);
      }
    }
  }

  ul {

    li {

      &:before {
        content: "•";

        @include mq($until: small) {
          @include rem(left, $gutter-size*0.4);
          @include font-size(18px, 21px);
        }

        @include mq($from: small) {
          @include rem(left, $gutter-size*0.75);
          @include font-size(20px, 24px);
        }
      }
    }
  }

  ol {
    counter-reset: olist;

    li {
      counter-increment: olist;

      &:before {
        content: counter(olist) ".";

        @include mq($until: small) {
          @include rem(left, $gutter-size/4);
        }

        @include mq($from: small) {
          @include rem(left, $gutter-size/2);
        }
      }
    }
  }

  strong,
  b {
    font-weight: $font-bold;
    color: $color-foreground;
  }

  em,
  i {
    font-style: italic;
  }

  a {
    text-decoration: underline;
    color: $color-foreground;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    display: block;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    @include rem(box-shadow, 0 2px 6px rgba($color-black, 0.4));
  }
}
