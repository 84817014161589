/**
 * COMPONENT
 * Name: Dropdown
 ********************************* */

.c-dropdown {
  position: relative;
}


  /**
   * CONTAINER
   *********************** */
  .c-dropdown__container {
    position: relative;
    @include rem(width, 230px);
  }


    /**
     * PLACEHOLDER
     *********************** */
    .c-dropdown__placeholder {
      position: relative;
      width: 100%;
      @include rem(padding, $gutter-size/2 $gutter-size);
      @include font-size(16px, 26px);
      color: $color-secondary;
      @include rem(border, 2px solid $color-light-grey);
      @include rem(border-radius, $border-radius);
      cursor: pointer;
    }

      .c-dropdown__label {
        @include rem(padding-right, $gutter-size*2);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .c-dropdown__icon {
        position: absolute;
        top: 50%;
        @include rem(right, $gutter-size);
        color: $color-foreground;
        transform: translateY(-50%);
        pointer-events: none;

        .c-dropdown.is-active & {
          transform: translateY(-50%) rotate(180deg);
        }
      }


    /**
     * ITEMS
     *********************** */
    .c-dropdown__items {
      position: absolute;
      display: none;
      @include rem(height, 150px);
      top: 100%;
      right: 0;
      left: 0;
      @include rem(padding-top, 7px);
      @include rem(padding-bottom, 7px);
      background-color: $color-grey;
      @include rem(border, 2px solid $color-light-grey);
      @include rem(border-radius, $border-radius);
      overflow: auto;
      z-index: 9;

      .c-dropdown.is-active & {
        display: block;
      }
    }

      .c-dropdown__item {
        @include font-size(16px, 19px);
      }

        .c-dropdown__link {
          display: block;
          @include rem(padding, 7px $gutter-size);

          &:hover {
            background-color: $color-dark-grey;
          }
        }
