/**
 * COMPONENT
 * Name: Value
 ********************************* */

.c-value {
  position: relative;

  &:before {
    content: counter(grid) ".";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    font-weight: $font-black;
    text-align: center;
    color: $color-light-grey;
    opacity: 0.3;
    z-index: -1;

    @include mq($until: small) {
      @include font-size(100px, 60px);
    }

    @include mq($from: small, $until: medium) {
      @include font-size(140px, 100px);
    }

    @include mq($from: medium) {
      @include font-size(200px, 145px);
    }
  }
}


  /**
   * CONTENT
   *********************** */
  .c-value__content {
    @include rem(min-height, 150px);
    text-align: center;

    @include mq($until: small) {
      @include rem(padding-top, $gutter-size*4);
    }

    @include mq($from: small, $until: medium) {
      @include rem(padding-top, $gutter-size*1.5);
    }

    @include mq($from: medium) {
      @include rem(padding-top, $gutter-size*3);
    }
  }


    /**
     * TITLE
     *********************** */
    .c-value__title {
      font-weight: $font-black;
      text-transform: uppercase;

      @include mq($until: small) {
        @include font-size(16px, 20px);
      }

      @include mq($from: small, $until: medium) {
        @include font-size(20px, 26px);
      }

      @include mq($from: medium) {
        @include font-size(24px, 30px);
      }
    }


    /**
     * DESCRIPTION
     *********************** */
    .c-value__description {
      @include rem(max-width, 280px);
      margin-right: auto;
      margin-left: auto;
      color: $color-secondary;

      @include mq($until: small) {
        @include font-size(11px, 15px);
      }

      @include mq($from: small, $until: medium) {
        @include font-size(13px, 20px);
      }

      @include mq($until: medium) {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size/4);
        }
      }

      @include mq($from: medium) {
        @include font-size(16px, 24px);

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size/2);
        }
      }
    }
