/**
 * LAYOUT
 * Name: Main
 ********************************* */

.l-main {
  position: relative;
}


  /**
   * HEAD
   *********************** */
  .l-main__head {
    position: relative;
    z-index: 1;

    body.page-player & {

      @include mq($until: large) {
        display: none;
      }
    }
  }


  /**
   * BODY
   *********************** */
  .l-main__body {
    position: relative;
    z-index: 2;

    body.page-banner & {

      @include mq($until: medium) {
        @include rem(margin-top, -$gutter-size*8.5);
      }

      @include mq($from: medium) {
        @include rem(margin-top, -$gutter-size*4.5);
      }
    }

    body.page-player & {
      position: relative;
      z-index: 1;

      @include mq($until: large) {
        @include rem(padding-top, $gutter-size*1.5);
      }

      @include mq($from: large) {
        @include rem(margin-top, -$gutter-size*23);
      }
    }

    body.page-content & {

      &:not(:first-child) {

        @include mq($until: small) {
          @include rem(margin-top, $gutter-size*2);
        }

        @include mq($from: small, $until: medium) {
          @include rem(margin-top, $gutter-size*2.5);
        }

        @include mq($from: medium, $until: large) {
          @include rem(margin-top, $gutter-size*3);
        }

        @include mq($from: large) {
          @include rem(margin-top, $gutter-size*3.5);
        }
      }
    }

    body.page-account & {

      &:not(:first-child) {

        @include mq($until: small) {
          @include rem(margin-top, $gutter-size);
        }

        @include mq($from: small, $until: medium) {
          @include rem(margin-top, $gutter-size*1.75);
        }

        @include mq($from: medium, $until: large) {
          @include rem(margin-top, $gutter-size*2.5);
        }

        @include mq($from: large) {
          @include rem(margin-top, $gutter-size*3);
        }
      }
    }
  }


  /**
   * FOOT
   *********************** */
  .l-main__foot {

    &:not(:first-child) {

    }
  }
