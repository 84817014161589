.u-lazyload {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.u-lazyload-preload {}

.u-lazyload-loading {}

.u-lazyload-loaded {
  opacity: 1;
}
