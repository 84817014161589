/**
 * COMPONENT
 * Name: Title
 ********************************* */

.c-title {
  position: relative;
  font-weight: $font-black;

  @include mq($until: small) {
    @include font-size(30px, 36px);
  }

  @include mq($from: small, $until: medium) {
    @include font-size(40px, 48px);
  }

  @include mq($from: medium, $until: large) {
    @include font-size(50px, 60px);
  }

  @include mq($from: large) {
    @include font-size(60px, 72px);
  }
}
