/**
 * LAYOUT
 * Name: Page
 ********************************* */

.l-page {
  position: relative;
  overflow: hidden;
}


  /**
   * HEAD
   *********************** */
  .l-page__head {
    position: relative;
    z-index: 2;

    // html.js & {
    //   transition: opacity 0.8s ease;
    //   opacity: 0;
    // }

    // html.js body.is-ready & {
    //   opacity: 1;
    // }

    @include mq($until: small) {
      @include rem(padding-top, $gutter-size*3.5);
    }

    @include mq($from: small, $until: medium) {
      @include rem(padding-top, $gutter-size*4.5);
    }

    @include mq($from: medium, $until: large) {
      @include rem(padding-top, $gutter-size*5.5);
    }

    @include mq($from: large) {
      @include rem(padding-top, $gutter-size*6.5);
    }
  }


  /**
   * BODY
   *********************** */
  .l-page__body {
    position: relative;
    z-index: 1;

    html.js & {
      // transition: opacity 0.8s ease 0.4s;
      transition: opacity 0.8s ease;
      opacity: 0;
    }

    html.js body.is-ready & {
      opacity: 1;
    }

    @include mq($until: small) {
      @include rem(padding-bottom, $gutter-size*2);

      body.page-content &:not(:first-child) {
        @include rem(margin-top, $gutter-size*2);
      }
    }

    @include mq($from: small, $until: medium) {
      @include rem(padding-bottom, $gutter-size*3);

      body.page-content &:not(:first-child) {
        @include rem(margin-top, $gutter-size*2.5);
      }
    }

    @include mq($from: medium, $until: large) {
      @include rem(padding-bottom, $gutter-size*4);

      body.page-content &:not(:first-child) {
        @include rem(margin-top, $gutter-size*3);
      }
    }

    @include mq($from: large) {
      @include rem(padding-bottom, $gutter-size*5);

      body.page-content &:not(:first-child) {
        @include rem(margin-top, $gutter-size*3.5);
      }
    }
  }


  /**
   * FOOT
   *********************** */
  .l-page__foot {}
