/**
 * VIEWS
 * Name: Search
 ********************************* */

.v-search {
  position: relative;

  ::placeholder {
    color: $color-secondary;
  }

  :-ms-input-placeholder {
    color: $color-secondary;
  }

  ::-ms-input-placeholder {
    color: $color-secondary;
  }
}


  /**
   * WRAPPER
   *********************** */
  .v-search__wrapper {}


    /**
     * HEAD
     *********************** */
    .v-search__head {
      border-bottom: 1px solid $color-grey;
    }


      /**
       * CONTAINER
       *********************** */
      .v-search__container {
        position: relative;
        @include rem(padding-right, $gutter-size);
        @include rem(padding-left, $gutter-size);

        @include mq($from: container) {
          margin-left: calc((100vw - 1180px) / 2);
        }
      }


        /**
         * CLOSE LINK
         *********************** */
        .v-search__link {
          position: absolute;
          display: block;
          top: 50%;
          @include rem(right, $gutter-size*2);
          transform: translateY(-50%);
          z-index: 2;
        }

          .v-search__close {
            display: block;

            @include mq($until: small) {
              @include rem(width, 16px);
              @include rem(height, 16px);
            }

            @include mq($from: small, $until: medium) {
              @include rem(width, 20px);
              @include rem(height, 20px);
            }

            @include mq($from: medium, $until: large) {
              @include rem(width, 24px);
              @include rem(height, 24px);
            }
          }


        /**
         * FORM
         *********************** */
        .v-search__form {
          position: relative;
        }

          .v-search__icon {
            position: absolute;
            display: block;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            pointer-events: none;

            @include mq($until: small) {
              @include rem(width, 16px);
              @include rem(height, 16px);
            }

            @include mq($from: small, $until: medium) {
              @include rem(width, 20px);
              @include rem(height, 20px);
            }

            @include mq($from: medium, $until: large) {
              @include rem(width, 24px);
              @include rem(height, 24px);
            }
          }

          .v-search__input {
            @include appearance;
            display: block;
            width: 100%;
            outline: none;

            @include mq($until: small) {
              @include rem(height, 70px);
              @include rem(padding-left, $gutter-size*1.5);
              @include rem(padding-right, $gutter-size*2);
              @include font-size(16px, 19px);
            }

            @include mq($from: small, $until: medium) {
              @include rem(height, 88px);
              @include rem(padding-left, $gutter-size*2);
              @include rem(padding-right, $gutter-size*2.5);
              @include font-size(20px, 24px);
            }

            @include mq($from: medium, $until: large) {
              @include rem(height, 106px);
              @include rem(padding-left, $gutter-size*2.5);
              @include rem(padding-right, $gutter-size*3);
              @include font-size(26px, 32px);
            }

            @include mq($from: large) {
              @include rem(height, 124px);
              @include rem(padding-left, $gutter-size*3);
              @include rem(padding-right, $gutter-size*3.5);
              @include font-size(30px, 36px);
            }

            &:focus + .v-search__title,
            &:valid + .v-search__title {
              opacity: 0;
            }
          }

          .v-search__title {
            position: absolute;
            top: 50%;
            left: 0;
            color: $color-secondary;
            pointer-events: none;
            transform: translateY(-50%);

            @include mq($until: small) {
              @include rem(padding-left, $gutter-size*1.5);
              @include rem(padding-right, $gutter-size*2);
              @include font-size(16px, 19px);
            }

            @include mq($from: small, $until: medium) {
              @include rem(padding-left, $gutter-size*2);
              @include rem(padding-right, $gutter-size*2.5);
              @include font-size(20px, 24px);
            }

            @include mq($from: medium, $until: large) {
              @include rem(padding-left, $gutter-size*2.5);
              @include rem(padding-right, $gutter-size*3);
              @include font-size(26px, 32px);
            }

            @include mq($from: large) {
              @include rem(padding-left, $gutter-size*3);
              @include rem(padding-right, $gutter-size*3.5);
              @include font-size(30px, 36px);
            }
          }


    /**
     * BODY
     *********************** */
    .v-search__body {

      &:not(:first-child) {

        @include mq($until: medium) {
          @include rem(margin-top, $gutter-size*1.5);
        }

        @include mq($from: medium) {
          @include rem(margin-top, $gutter-size*2.5);
        }
      }
    }
