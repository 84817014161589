/**
 * COMPONENT
 * Name: Form
 ********************************* */

.c-form {
  position: relative;

  &:not(:first-child) {

    @include mq($until: medium) {
      @include rem(margin-top, $gutter-size/2);
    }

    @include mq($from: medium) {
      @include rem(margin-top, $gutter-size);
    }
  }

  * {
    outline: none;
  }

  ::placeholder {

  }

  :-ms-input-placeholder {

  }

  ::-ms-input-placeholder {

  }
}


  /**
   * FIELDS
   *********************** */
  .c-form__fields {}


    /**
     * ITEM
     *********************** */
    .c-form__item {

      &:not(:first-child) {

        @include mq($until: medium) {
          @include rem(margin-top, $gutter-size*0.75);
        }

        @include mq($from: medium) {
          @include rem(margin-top, $gutter-size);
        }
      }
    }

    .c-form__item--flex {

      @include mq($from: medium) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
      }
    }


      /**
       * FIELD
       *********************** */
      .c-form__field {}

      .c-form__field--text {}
      .c-form__field--email {}
      .c-form__field--select {}
      .c-form__field--textarea {}

      .c-form__field--checkbox {
        @include rem(max-width, 350px);
      }

      .c-form__field--submit {
        text-align: right;

        @include mq($until: medium) {

          &:not(:first-child) {
            @include rem(margin-top, $gutter-size*1.5);
          }
        }

        @include mq($from: medium) {
          flex: 1 1 auto;

          &:not(:first-child) {
            @include rem(padding-left, $gutter-size*1.5);
          }
        }
      }

      .c-form__field--required {}


        /**
         * LABEL
         *********************** */
        .c-form__label {
          color: $color-secondary;
          cursor: pointer;

          .c-form__field--required & {

            &:after {
              content: " *";
            }
          }
        }


        /**
         * INNER
         *********************** */
        .c-form__inner {
          display: block;
          position: relative;

          &:not(:first-child) {
            @include rem(margin-top, $gutter-size/2);
          }

          .c-form__field--checkbox & {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-start;
          }
        }

          .c-form__input,
          .c-form__select,
          .c-form__textarea,
          .c-form__checkbox {
            @include appearance;

            &[disabled] {
              background-color: $color-grey;
              border-color: $color-light-grey !important;
              pointer-events: none;
            }
          }

          .c-form__input,
          .c-form__select,
          .c-form__textarea {
            width: 100%;
            @include rem(padding, $gutter-size*0.75 $gutter-size);
            @include font-size(16px, 20px);
            @include rem(border, 2px solid $color-light-grey);
            @include rem(border-radius, $border-radius);
            transition: border-color 0.2s ease;

            &:hover,
            &:focus {
              border-color: $color-pale-white;
            }
          }

          select.c-form__select::-ms-expand {
            display: none;
          }

          .c-form__textarea {
            resize: vertical;
            @include rem(min-height, 250px);
          }

          .c-form__checkbox {
            position: absolute;
            @include rem(width, 15px);
            @include rem(height, 15px);
            top: 0;
            left: 0;
            cursor: pointer;

            &:checked + .c-form__fakebox {
              border-color: $color-pale-white;

              &:before {
                opacity: 1;
              }
            }
          }

          .c-form__fakebox {
            position: relative;
            flex: 0 0 auto;
            display: block;
            @include rem(width, 15px);
            @include rem(height, 15px);
            @include rem(border, 2px solid $color-light-grey);
            @include rem(border-radius, $border-radius-small);
            transition: border-color 0.2s ease;
            cursor: pointer;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              @include rem(margin, 2px);
              background-color: $color-primary;
              @include rem(border-radius, 1px);
              opacity: 0;
              transition: opacity 0.2s ease;
            }
          }

          .c-form__caption {
            @include font-size(12px, 19px);
            color: $color-secondary;
            cursor: pointer;

            &:not(:first-child) {
              @include rem(padding-left, $gutter-size/2);
            }
          }

            .c-form__link {
              text-decoration: underline;
              white-space: nowrap;
              color: $color-foreground;
            }

          .c-form__icon {

            .c-form__field--select & {
              position: absolute;
              top: 50%;
              @include rem(right, $gutter-size);
              transform: translateY(-50%);
              pointer-events: none;
            }
          }


        /**
         * FEEDBACK
         *********************** */
        .c-form__feedback,
        .c-form .help-text {
          @include font-size(12px, 15px);

          &:not(:first-child) {

            @include mq($until: medium) {
              @include rem(margin-top, $gutter-size*0.5);
            }

            @include mq($from: medium) {
              @include rem(margin-top, $gutter-size*0.75);
            }
          }
        }
