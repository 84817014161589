/**
 * COMPONENT
 * Name: Connect
 ********************************* */

.c-connect {
  position: relative;
}

.c-connect--logged {}


  /**
   * LINK
   *********************** */
  .c-connect__link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }


    /**
     * ICON
     *********************** */
    .c-connect__icon {
      flex: 0 0 auto;
      transition: color 0.2s ease;

      .c-connect__link:hover &,
      .c-connect--logged &,
      .c-connect--active & {
        color: $color-primary;
      }
    }


    /**
     * LABEL
     *********************** */
    .c-connect__label {
      transition: color 0.2s ease;

      .c-connect__link:hover &,
      .c-connect--active & {
        color: $color-secondary;
      }

      @include mq($until: large) {
        display: none;
      }

      @include mq($from: large) {
        @include font-size(20px, 24px);
        font-weight: $font-bold;

        &:not(:first-child) {
          @include rem(padding-left, $gutter-size*0.75);
        }
      }
    }
