/**
 * COMPONENT
 * Name: Highlight
 ********************************* */

.c-highlight {
  position: relative;
}


/**
 * TYPES
 *********************** */
.c-highlight--vertical {}

.c-highlight--horizontal {

  @include mq($until: large) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  @include mq($from: large) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
}


  /**
   * HEAD
   *********************** */
  .c-highlight__head {
    position: relative;

    .c-highlight--vertical & {

      @include mq($until: medium) {
        @include rem(margin-right, -$gutter-size);
        @include rem(margin-left, -$gutter-size);
      }
    }

    .c-highlight--horizontal & {

      @include mq($from: large) {
        flex: 0 0 auto;
      }
    }
  }


    /**
     * VIDEO
     *********************** */
    .c-highlight__video {
      position: relative;
      display: block;
      padding-top: 56.5%;
      background-color: $color-background;
      @include rem(box-shadow, 0 2px 6px rgba($color-black, 0.4));

      &.js-video {
        opacity: 0;
      }

      &.js-video.is-visible {
        opacity: 1;
      }

      iframe,
      .iframe {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    .c-highlight__video[data-source="digiteka"] {

      &.js-video {
        opacity: 1 !important;
      }

      + .c-highlight__poster {
        display: none !important;
      }
    }


    /**
     * LIVE
     *********************** */
    .c-highlight__live {
      position: relative;
      display: block;
      z-index: 1;
    }


    /**
     * LINK
     *********************** */
    .c-highlight__link {

      .c-highlight__head & {
        display: block;
        background-color: $color-background;
        @include rem(border-radius, $border-radius);
        @include rem(box-shadow, 0 2px 6px rgba($color-black, 0.4));
        overflow: hidden;
      }

      .c-highlight--horizontal .c-highlight__body & {
        display: block;
        @include rem(padding-right, $gutter-size*2);
      }
    }


    /**
     * POSTER / VEIL
     *********************** */
    .c-highlight__poster,
    .c-highlight__veil {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      @include rem(box-shadow, 0 2px 6px rgba($color-black, 0.4));
      cursor: pointer;
      overflow: hidden;
    }

    .c-highlight__poster {
      background-color: $color-background;
      @include rem(border-radius, $border-radius);
      z-index: 2;
    }

    .c-highlight__veil {
      background-color: rgba($color-background, 0);
      transition: background-color 0.3s ease, opacity 0.3s ease, z-index 0.3s ease;
      z-index: 999;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      &:hover {
        background-color: rgba($color-background, 0.5);
      }

      &.is-hidden {
        pointer-events: none;
        opacity: 0;
        z-index: -1;
      }

      @include mq($until: small) {

        &:before {
          background: linear-gradient(rgba($color-background, 0.5) 30%, $color-background 85%);
        }
      }

      @include mq($from: small) {

        &:before {
          background: linear-gradient(rgba($color-background, 0.3) 20%, $color-background 90%);
        }
      }
    }


      /**
       * PLAY
       *********************** */
      .c-highlight__play {
        position: absolute;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        top: 50%;
        left: 50%;
        background-color: $color-primary;
        transform: translate3d(-50%, -50%, 0);
        z-index: 2;

        @include mq($until: small) {
          @include rem(width, 50px);
          @include rem(height, 50px);
          @include rem(border-radius, 25px);

          .c-highlight__veil & {
            @include rem(width, 40px);
            @include rem(height, 40px);
            @include rem(border-radius, 20px);

            .c-highlight__icon {
              @include rem(width, 10px);
              @include rem(height, 14px);
            }
          }
        }

        @include mq($from: small) {
          @include rem(width, 60px);
          @include rem(height, 60px);
          @include rem(border-radius, 30px);
        }
      }


      /**
       * PICTURE
       *********************** */
      .c-highlight__picture {
        display: block;
        height: auto;
        @include rem(box-shadow, 0 2px 6px rgba($color-black, 0.4));
        transition: opacity 0.3s ease;
        opacity: 1;

        .c-highlight__poster:hover &,
        .c-highlight__link:hover & {
          opacity: 0.5;
        }

        .c-highlight__poster & {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }


  /**
   * BODY
   *********************** */
  .c-highlight__body {
    position: relative;

    .c-highlight--horizontal & {

      @include mq($until: large) {
        width: 100%;
      }

      @include mq($from: large) {
        flex: 1 1 auto;
      }
    }

    &:not(:first-child) {

      @include mq($until: medium) {
        @include rem(margin-top, $gutter-size);
      }

      @include mq($from: medium) {
        @include rem(margin-top, $gutter-size*1.25);
      }

      @include mq($from: large) {

        .c-highlight--horizontal & {
          @include rem(margin-left, $gutter-size*1.5);
        }
      }
    }
  }


    /**
     * ACTIONS
     *********************** */
    .c-highlight__actions {
      position: absolute;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      @include rem(top, $gutter-size/4);
      right: 0;

      .c-highlight--horizontal & {

        @include mq($until: large) {
          top: 0;
        }
      }
    }

      .c-highlight__action {
        flex: 0 0 auto;

        &:not(:first-child) {
          @include rem(margin-left, $gutter-size);
        }
      }

        .c-highlight__button {
          @include appearance;
          transition: color 0.2s ease;
          cursor: pointer;

          &:hover,
          &.is-active {
            color: $color-primary;
          }
        }

    .c-highlight__title {
      font-weight: $font-black;

      .c-highlight--vertical & {
        @include rem(padding-right, $gutter-size*5);

        @include mq($until: small) {
          @include font-size(20px, 24px);
        }

        @include mq($from: small, $until: medium) {
          @include font-size(25px, 30px);
        }

        @include mq($from: medium, $until: large) {
          @include font-size(30px, 36px);
        }

        @include mq($from: large) {
          @include font-size(35px, 42px);
        }
      }

      .c-highlight--horizontal & {

        @include mq($until: small) {
          @include font-size(20px, 24px);
        }

        @include mq($from: small, $until: large) {
          @include font-size(22px, 26px);
        }

        @include mq($from: large) {
          @include font-size(25px, 30px);
        }
      }
    }

    .c-highlight__tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      @include rem(margin-top, -7px);
      @include rem(margin-right, -5px);
      @include rem(margin-left, -5px);

      &:not(:first-child) {

        @include mq($until: medium) {
          @include rem(margin-top, $gutter-size/2);
        }

        @include mq($from: medium) {
          @include rem(margin-top, $gutter-size);
        }
      }
    }

      .c-highlight__tag {
        flex: 0 0 auto;
        @include rem(padding, 7px 5px);
      }

    .c-highlight__description {
      @include font-size(14px, 21px);
      color: $color-secondary;

      &:not(:first-child) {

        @include mq($until: medium) {
          @include rem(margin-top, $gutter-size);
        }

        @include mq($from: medium) {
          @include rem(margin-top, $gutter-size*1.5);
        }
      }
    }

    .c-highlight__features {

      &:not(:first-child) {

        @include mq($until: medium) {
          @include rem(margin-top, $gutter-size);
        }

        @include mq($from: medium) {
          @include rem(margin-top, $gutter-size*2);
        }
      }
    }
