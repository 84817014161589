/**
 * VIEWS
 * Name: Newscast
 ********************************* */

.v-newscast {
  position: relative;
}


  /**
   * CONTAINER
   *********************** */
  .v-newscast__container {

    @include mq($from: medium) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    @include mq($from: medium, $until: large) {
      @include rem(margin-right, -$gutter-size);
      @include rem(margin-left, -$gutter-size);
    }

    @include mq($from: large) {
      @include rem(margin-right, -$gutter-size*1.5);
      @include rem(margin-left, -$gutter-size*1.5);
    }
  }


    /**
     * ASIDE
     *********************** */
    .v-newscast__aside {

      @include mq($until: medium) {
        @include rem(max-width, 440px);
      }

      @include mq($from: medium) {
        flex: 0 0 auto;
        @include rem(width, 380px);
      }

      @include mq($from: medium, $until: large) {
        @include rem(padding-right, $gutter-size);
        @include rem(padding-left, $gutter-size);
      }

      @include mq($from: large) {
        @include rem(padding-right, $gutter-size*1.5);
        @include rem(padding-left, $gutter-size*1.5);
      }
    }


    /**
     * MAIN
     *********************** */
    .v-newscast__main {

      @include mq($until: medium) {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size*2);
        }
      }

      @include mq($from: medium, $until: large) {
        @include rem(padding-right, $gutter-size);
        @include rem(padding-left, $gutter-size);
      }

      @include mq($from: large) {
        @include rem(padding-right, $gutter-size*1.5);
        @include rem(padding-left, $gutter-size*1.5);
      }
    }


      /**
       * HEADING
       *********************** */
      .v-newscast__heading {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: baseline;
      }

        .v-newscast__icon {
          flex: 0 0 auto;
        }

        .v-newscast__title {

          &:not(:first-child) {
            @include rem(margin-left, $gutter-size);
          }

          @include mq($until: small) {
            @include font-size(18px, 22px);
          }

          @include mq($from: small) {
            @include font-size(22px, 26px);
          }
        }


      /**
       * ITEMS
       *********************** */
      .v-newscast__items {

        @include mq($until: small) {

          &:not(:first-child) {
            @include rem(margin-top, $gutter-size);
          }
        }

        @include mq($from: small) {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          @include rem(margin-top, $gutter-size*0.75);
          @include rem(margin-right, -7px);
          @include rem(margin-left, -7px);
        }
      }

        .v-newscast__item {

          @include mq($until: small) {
            width: 100%;

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size/2);
            }
          }

          @include mq($from: small) {
            @include rem(padding, 6px 7px);
          }

          @include mq($from: small, $until: medium) {
            width: 50%;
          }

          @include mq($from: medium, $until: large) {
            width: 100%;
          }

          @include mq($from: large) {
            width: 50%;
          }
        }
