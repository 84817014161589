/**
 * VIEWS
 * Name: Gallery
 ********************************* */

.v-gallery {
  position: relative;
}


  /**
   * ITEMS
   *********************** */
  .v-gallery__items {

    @include mq($from: small) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-end;
    }
  }


    /**
     * ITEM
     *********************** */
    .v-gallery__item {
      position: relative;

      @include mq($until: small) {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size*0.75);
        }
      }

      @include mq($from: small) {
        flex: 0 0 auto;
        width: 50%;

        &:first-child {
          text-align: right;
          z-index: 1;
        }

        &:last-child {
          @include rem(margin-bottom, $gutter-size*1.5);
          text-align: left;
          transform: translateX(-$gutter-size);
          z-index: 2;
        }
      }
    }


      /**
       * PICTURE
       *********************** */
      .v-gallery__picture {
        height: auto;
        @include rem(box-shadow, 0 2px 10px rgba($color-black, 0.4));

        @include mq($until: small) {
          width: 100%;
        }
      }
