/**
 * COMPONENT
 * Name: Share
 ********************************* */

.c-share {
  position: relative;
}


  /**
   * CONTAINER
   *********************** */
  .c-share__container {

    @include mq($from: small) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      @include rem(margin-right, -$gutter-size/2);
      @include rem(margin-left, -$gutter-size/2);
    }
  }


    /**
     * ITEM
     *********************** */
    .c-share__item {

      @include mq($from: small) {
        @include rem(padding, $gutter-size/4 $gutter-size/2);
      }
    }


      /**
       * BUTTON
       *********************** */
      .c-share__button {
        @include appearance;
        position: relative;
        max-width: 100%;
        @include rem(padding, 8px 12px);
        text-align: left;
        color: $color-foreground;
        background-color: $color-light-grey;
        @include rem(border, 2px solid $color-light-grey);
        @include rem(border-radius, $border-radius);
        transition: background-color 0.2s ease, border-color 0.2s ease;
        cursor: pointer;

        &:hover {
          background-color: $color-grey;
          border-color: $color-grey;
        }

        .c-share.is-active & {
          background-color: $color-grey;
          border-color: $color-foreground;
        }
      }


        /**
         * INNER
         *********************** */
        .c-share__inner {
          position: relative;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          @include rem(height, 24px);
          white-space: nowrap;

          .c-share__button & {
            overflow: hidden;
          }

          .c-share.is-active .c-share__button & {
            @include rem(margin-right, -12px);
            @include rem(padding-right, 12px);

            &:after {
              content: '';
              position: absolute;
              @include rem(width, 12px);
              top: 0;
              right: 0;
              bottom: 0;
              background: linear-gradient(to left, $color-grey, rgba($color-grey, 0));
            }
          }
        }


      /**
       * MESSAGE
       *********************** */
      .c-share__message {
        flex: 0 0 auto;
        transform: translate3d(0, 0, 0);
        transition: transform 0.8s ease 0.2s, opacity 0.8s ease 0.2s;
        visibility: hidden;
        opacity: 1;
        pointer-events: none;

        .c-share.is-animating & {
          visibility: visible;
          opacity: 0;
          transform: translate3d(0, -$gutter-size/2, 0);
        }
      }


        /**
         * ICON
         *********************** */
        .c-share__icon {
          display: inline-block;
          vertical-align: middle;

          + .c-share__label {
            @include rem(margin-left, $gutter-size/2);
          }

          .c-share__message & {
            color: $color-foreground;
            fill: $color-success;
          }
        }


        /**
         * LABEL
         *********************** */
        .c-share__label {
          display: inline-block;
          vertical-align: middle;
          font-weight: $font-bold;

          .c-share__button & {
            @include font-size(16px, 20px);
          }

          .c-share__message & {
            @include font-size(12px, 16px);
            color: $color-success;
          }
        }

          .c-share__value {
            @include appearance;
            @include rem(margin-right, -12px);
          }
