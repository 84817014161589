/**
 * COMPONENT
 * Name: Program
 ********************************* */

.c-program {
  position: relative;

  @include mq($until: medium) {
    @include rem(max-width, 190px);
  }

  @include mq($from: medium) {
    @include rem(max-width, 220px);
  }
}

.c-program--current {}


  /**
   * LINK
   *********************** */
  .c-program__link {
    display: block;
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s ease;

    &:hover {
      transform: translate3d(0, -$gutter-size/4, 0);
    }
  }


    /**
     * HEAD
     *********************** */
    .c-program__head {
      @include rem(padding-right, $gutter-size/2);
      @include rem(padding-left, $gutter-size/2);
    }

      .c-program__label {
        @include font-size(22px, 26px);
        font-weight: $font-black;
      }


    /**
     * BODY
     *********************** */
    .c-program__body {
      position: relative;
      @include rem(padding, $gutter-size/2);

      .c-program--current & {
        background-color: $color-background;
        @include rem(border-radius, $border-radius);
        @include rem(box-shadow, 0 2px 4px rgba(#000, 0.4));
      }
    }

      .c-program__picture {
        @include rem(border-radius, $border-radius);
        @include rem(box-shadow, 0 2px 6px rgba(#000, 0.4));

        @include mq($until: medium) {
          @include rem(width, 170px);
          height: auto;
        }
      }

      .c-program__caption {
        position: absolute;
        @include rem(top, $gutter-size/2);
        @include rem(left, $gutter-size/2);
        @include rem(padding, 4px 5px);
        @include font-size(12px, 14px);
        font-weight: $font-black;
        text-transform: uppercase;
        background-color: $color-primary;
        @include rem(border-radius, $border-radius-small);
      }

      .c-program__heading {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size/2);
        }
      }

      .c-program__title {}

      .c-program__subtitle {
        @include font-size(12px, 14px);
        color: $color-secondary;

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size/4);
        }
      }
