/**
 * COMPONENT
 * Name: News
 ********************************* */

.c-news {
  position: relative;
}


  /**
   * LINK
   *********************** */
  .c-news__link {
    @include font-size(14px, 21px);
  }

    .c-news__date {
      color: $color-secondary;
    }

    .c-news__title {}
